import React, { useEffect, useRef, useState } from 'react'

import useAuth from 'hooks/auth'

import { useReactToPrint } from 'react-to-print'

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
} from '@mui/material'

import { DataGrid, esES } from '@mui/x-data-grid'

import Label from 'pages/label'

import { format } from 'date-fns'
import { es } from 'date-fns/locale'

const Orders = (props) => {

  const { firestore, functions } = useAuth()

  const { getOrders, orders } = firestore
  const { sendEmail } = functions

  const [rows, setRows] = useState([])

  const [order, setOrder] = useState()
  const [data, setData] = useState({})

  const [open, setOpen] = useState(false)
  const handleClose = () => {
    setOpen(false)
  }

  const [emailOrder, setEmailOrder] = useState()
  const [openEmailDialog, setOpenEmailDialog] = useState(false)
  const handleEmailClose = () => {
    setOpenEmailDialog(false)
  }

  const onPrint = (id) => {
    const order = orders.filter(order => order.id === id)[0]
    if (order === undefined) return

    setOrder(order)
    setData(order.data)
    setOpen(true)
  }

  const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  const onSendEmailOrder = (order) => {
    setEmailOrder(order)
    setOpenEmailDialog(true)
  }

  const onSendEmail = async () => {
    await sendEmail({
      'order': emailOrder
    })
    setOpenEmailDialog(false)
    setEmailOrder(undefined)
  }

  const columns = [
    // { field: 'id', headerName: 'ID', width: 70 },
    { 
      field: 'id', 
      headerName: 'Orden', 
      width: 130, 
      renderCell: (params) => {
        return params.value.slice(-10).toUpperCase()
      }
    },
    { field: 'name', headerName: 'Nombre', minWidth: 200, flex: 1 },
    { field: 'type', headerName: 'Grano', minWidth: 40, flex: 1 },
    { field: 'qty', headerName: 'Bolsas', minWidth: 20, flex: 1 },
    { field: 'amount', headerName: 'Total', minWidth: 30, flex: 1 },
    { field: 'status', headerName: 'Estado', minWidth: 100, flex: 1 },
    { 
      field: 'order',
      headerName: 'Email',
      minWidth: 40,
      flex: 1,
      renderCell: (params) => {
        return <Button variant='contained' disableElevation size='small' onClick={() => onSendEmailOrder(params.value)}>Enviar</Button>
      }
    },
    { 
      field: 'guia',
      headerName: 'Guía',
      minWidth: 40,
      flex: 1,
      renderCell: (params) => {
        return <Button component='a' href={params.value} target='_blank' disabled={params.value === ''} variant='contained' disableElevation size='small'>{params.value === '' ? 'N/D' : 'Ver'}</Button>
      }
    },
    { 
      field: 'tracking', 
      headerName: 'Rastreo',
      minWidth: 40,
      flex: 1,
      renderCell: (params) => {
        return <Button component='a' href={params.value} target='_blank' disabled={params.value === ''} variant='contained' disableElevation size='small'>{params.value === '' ? 'N/D' : 'Ver'}</Button>
      }
    },
    { 
      field: 'printed', 
      headerName: 'Etiqueta',
      minWidth: 40,
      flex: 1,
      renderCell: (params) => {
        return <Button color={ params.value ? 'rubricaGreen' : 'primary' } variant='contained' disableElevation size='small' onClick={() => onPrint(params.id)}>{params.value ? 'Reimprimir' : 'Imprimir'}</Button>
      }
    },
    { 
      field: 'date', 
      headerName: 'Fecha', 
      minWidth: 90,
      flex: 1,
      renderCell: (params) => {
        return capitalizeFirstLetter(format(new Date(params.value * 1000), 'dd/MM/yy' /*'MMM d, yyyy'*/, { locale: es }));
      }
    },
  ]

  useEffect(() => {
    const getAllOrders = async () => {
      await getOrders()
    }

    getAllOrders()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (orders === undefined) return
    var data = []
    orders.forEach((order) => {
      const statuses = {
        'created': 'Creada',
        'completed': 'Pagada',
        'charged': 'Pagada',
        'active': 'Pagada',
        'declined': 'Declinada',
      }

      const grains = {
        'entero': 'Entero',
        'molido': 'Molido',
      }

      data.push({
        'id': order['id'],
        'name': order['customer']['name'],
        'qty': order['data']['rubrica']['bolsas'],
        'type': grains[order['data']['rubrica']['grano']],
        'printed': order['printed'],
        'status': order['subscriptionId'] !== undefined ? 'Suscrita' : statuses[order['status']],
        'guia': order['shipment'] !== undefined ? order['shipment']['guia'] : '',
        'tracking': order['shipment'] !== undefined ? order['shipment']['tracking_link'] : '',
        'amount': `$${order['amount']}`,
        'date': order['createdAt'].seconds,
        'order': order,
      })
    })
    setRows(data)
  }, [orders])

  const componentRef = useRef()
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  })

  const onHandlePrint = async () => {
    handlePrint()
    await firestore.updateOrder(order['id'], {
      'printed': true,
    })
    setOpen(false)
  }

  class ComponentToPrint extends React.PureComponent {
    render() {
      return (
        <Label data={data} />
      )
    }
  }

  return (
    <Grid container spacing={2} alignItems='center'>
      <Grid item xs={12}>
        <DataGrid
          autoHeight
          // disableColumnMenu
          columns={columns}
          localeText={esES.components.MuiDataGrid.defaultProps.localeText}
          pageSize={20}
          rows={rows}
          rowsPerPageOptions={[5]}
          sx={{ borderWidth: 0 }}
        />
      </Grid>
      <Dialog
        open={open}
        onClose={handleClose}
      >
        <DialogTitle>
          Imprimir etiqueta
        </DialogTitle>
        <DialogContent>
          <ComponentToPrint ref={componentRef} />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancelar</Button>
          <Button variant='contained' disableElevation onClick={onHandlePrint} autoFocus>
            Imprimir
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={openEmailDialog}
        onClose={handleEmailClose}
      >
        <DialogTitle>
          ¿Seguro que quieres enviar este correo?
        </DialogTitle>
        <DialogContent>
          {/* <DialogContentText>
            Esta acción no puede deshacerse
          </DialogContentText> */}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleEmailClose}><b style={{ fontWeight: 900 }}>Cancelar</b></Button>
          <Button color='primary' variant='contained' disableElevation onClick={onSendEmail} autoFocus>
            <b style={{ fontWeight: 900 }}>Enviar</b>
          </Button>
        </DialogActions>
      </Dialog>
    </Grid>
  )
}

export default Orders