import React, { useState } from 'react'

import {
  FormControl,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
} from '@mui/material'

const Chocolate = (props) => {
  const { current, getCurrentValue } = props

  const values = {
    'chocolate_1': 5,
    'chocolate_2': 4.5,
    'chocolate_3': 4,
    'chocolate_4': 3.5,
    'chocolate_5': 3,
    'chocolate_6': 2,
    'chocolate_7': 1,
  }
  
  const [value, setValue] = useState(current !== undefined ? current.id : '')

  const handleRadioChange = (event) => {
    setValue(event.target.value)
    getCurrentValue({
      'id': event.target.value,
      'value': values[event.target.value],
    })
  }

  return (
    <Grid container alignContent='center' alignItems='center' direction='column' justifyContent='center'>
      <Grid item xs={12}>
        <FormControl>
          <RadioGroup value={value} onChange={handleRadioChange}>
            <FormControlLabel value='chocolate_1' control={<Radio />} label='Chocolate oscuro' />
            <FormControlLabel value='chocolate_2' control={<Radio />} label='Chocolate con leche' />
            <FormControlLabel value='chocolate_3' control={<Radio />} label='Relleno con algún licor' />
            <FormControlLabel value='chocolate_4' control={<Radio />} label='Praline o nueces' />
            <FormControlLabel value='chocolate_5' control={<Radio />} label='Chocolate blanco' />
            <FormControlLabel value='chocolate_6' control={<Radio />} label='Con rayadura' />
            <FormControlLabel value='chocolate_7' control={<Radio />} label='Con menta' />
          </RadioGroup>
        </FormControl>
      </Grid>
    </Grid>
    
  )
}

export default Chocolate