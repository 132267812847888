import React, { useState } from 'react'

import {
  FormControl,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
} from '@mui/material'

const Flavor = (props) => {
  const { aroma, current, getCurrentValue } = props

  const values = {
    'flavor_1': { 'label': 'Lima', 'value': 5 },
    'flavor_2': { 'label': 'Mandarina', 'value': 4 },
    'flavor_3': { 'label': 'Toronja', 'value': 3 },
    'flavor_4': { 'label': 'Naranja', 'value': 2 },

    'flavor_5': { 'label': 'Cereza negra', 'value': 5 },
    'flavor_6': { 'label': 'Zarzamora', 'value': 4 },
    'flavor_7': { 'label': 'Frambuesa', 'value': 3 },
    'flavor_8': { 'label': 'Fresa', 'value': 2 },
    
    'flavor_9': { 'label': 'Guayaba', 'value': 5 },
    'flavor_10': { 'label': 'Ciruela', 'value': 4 },
    'flavor_11': { 'label': 'Maracuyá', 'value': 3 },
    'flavor_12': { 'label': 'Mango', 'value': 2 },

    'flavor_13': { 'label': 'Jazmin', 'value': 5 },
    'flavor_14': { 'label': 'Azahar', 'value': 4 },
    'flavor_15': { 'label': 'Lemongrass', 'value': 3 },
    'flavor_16': { 'label': 'Jengibre', 'value': 2 },

    'flavor_17': { 'label': 'Cacao', 'value': 5 },
    'flavor_18': { 'label': 'Madera', 'value': 4 },
    'flavor_19': { 'label': 'Te negro', 'value': 3 },
    'flavor_20': { 'label': 'Tabaco', 'value': 2 },

    'flavor_21': { 'label': 'Canela', 'value': 5 },
    'flavor_22': { 'label': 'Pimienta', 'value': 4 },
    'flavor_23': { 'label': 'Cardamomo', 'value': 3 },
    'flavor_24': { 'label': 'Clavo', 'value': 2 },

    'flavor_25': { 'label': 'Almendra tostada', 'value': 5 },
    'flavor_26': { 'label': 'Malta', 'value': 4 },
    'flavor_27': { 'label': 'Caramelo', 'value': 3 },
    'flavor_28': { 'label': 'Nuez', 'value': 2 },
  }

  const items = {
    'aroma_1': ['flavor_1', 'flavor_2', 'flavor_3', 'flavor_4'],
    'aroma_2': ['flavor_5', 'flavor_6', 'flavor_7', 'flavor_8'],
    'aroma_3': ['flavor_9', 'flavor_10', 'flavor_11', 'flavor_12'],
    'aroma_4': ['flavor_13', 'flavor_14', 'flavor_15', 'flavor_16'],
    'aroma_5': ['flavor_17', 'flavor_18', 'flavor_19', 'flavor_20'],
    'aroma_6': ['flavor_21', 'flavor_22', 'flavor_23', 'flavor_24'],
    'aroma_7': ['flavor_25', 'flavor_26', 'flavor_27', 'flavor_28'],
  }
  
  const [value, setValue] = useState(current !== undefined ? current.id : '')

  const handleRadioChange = (event) => {
    setValue(event.target.value)
    getCurrentValue({
      'id': event.target.value,
      'value': values[event.target.value].value,
    })
  }

  return (
    <Grid container alignContent='center' alignItems='center' direction='column' justifyContent='center'>
      <Grid item xs={12}>
        <FormControl>
          <RadioGroup value={value} onChange={handleRadioChange}>
            {
              items[aroma].map((item) => <FormControlLabel key={`${aroma}_${item}`} value={item} control={<Radio />} label={values[item].label}/>)
            }
            {/* <FormControlLabel value='flavor_1' control={<Radio />} label='Cereza negra' />
            <FormControlLabel value='flavor_2' control={<Radio />} label='Zarzamoras' />
            <FormControlLabel value='flavor_3' control={<Radio />} label='Frambuesa' />
            <FormControlLabel value='flavor_4' control={<Radio />} label='Fresa' /> */}
          </RadioGroup>
        </FormControl>
      </Grid>
    </Grid>
    
  )
}

export default Flavor