import { createContext } from 'react'

import { useProvideAuth } from 'config/firebase/auth'
import { useProvideFirestore } from 'config/firebase/firestore'
import { useProvideFunctions } from 'config/firebase/functions'
import { useOpenPay } from 'config/openpay'
import { useFacturama } from 'config/facturama'

const FirebaseContext = createContext()

export const FirebaseProvider = ({ children }) => {
  const auth = useProvideAuth()
  const firestore = useProvideFirestore()
  const functions = useProvideFunctions()
  const openPay = useOpenPay()
  const facturama = useFacturama()

  return (
    <FirebaseContext.Provider value={{ auth, firestore, functions, openPay, facturama }}>
      { !auth.loading && children}
    </FirebaseContext.Provider>
  )
}

export default FirebaseContext