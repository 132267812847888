import React, { useState } from 'react'

import {
  Link as RouterLink,
  NavLink,
  Route,
  Routes,
  useLocation
} from 'react-router-dom'

import useAuth from 'hooks/auth'

import {
  AppBar,
  Avatar,
  Box,
  Breadcrumbs,
  Button,
  Drawer,
  Grid,
  IconButton,
  Link,
  List,
  ListItem,
  ListItemText,
  Toolbar,
  Typography,
} from '@mui/material'

import MenuIcon from '@mui/icons-material/Menu'
import NavigateNextIcon from '@mui/icons-material/NavigateNext'

import Home from 'pages/admin/home'
import Orders from 'pages/admin/orders'
import Coupons from './coupons'

const Admin = (props) => {

  const { window } = props

  const { auth } = useAuth()
  const { logout, user } = auth

  const drawerWidth = 240

  const [mobileOpen, setMobileOpen] = useState(false)

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen)
  }

  const drawer = (
    <>
      <Toolbar>
        <Grid container>
          <Grid item xs align='middle'>
            <Box sx={{ p: 2, width: '100%' }}>
              <img
                src='/assets/images/logo.png'
                alt='Rúbrica Logo'
                style={{ opacity: 0.9, verticalAlign: 'middle', width: '70%' }}
              />
            </Box>
          </Grid>
        </Grid>
      </Toolbar>
      <List sx={{ p: 2 }}>
        <ListItem sx={{ py: 0, pb: 1 }}>
          <ListItemText
            disableTypography
            primary={
              <Typography fontFamily='Righteous' variant='subtitle2' sx={{ fontWeight: 'bold', fontSize: 12, letterSpacing: '2px', opacity: 0.9 }}>RÚBRICA</Typography>
            }
          />
        </ListItem>
        <ListItem button 
          component={NavLink}
          end // https://stackoverflow.com/a/70551865
          to=''
          onClick={handleDrawerToggle}
          style={({ isActive }) => ({
            background: isActive ? '#933d3122' : '',
            borderRadius: isActive ? '8px' : '',
            color: isActive ? '#933d31' : '',
          })}
          sx={{
            '&:hover': {
              background: '#933d3122',
              borderRadius: '8px',
              color: '#933d31',
            },
            mb: 1,
            pl: 4
          }}
        >
          <ListItemText primary='Inicio' />
        </ListItem>
        <ListItem button
          component={NavLink}
          to='orders'
          onClick={handleDrawerToggle}
          style={({ isActive }) => ({
            background: isActive ? '#933d3122' : '',
            borderRadius: isActive ? '8px' : '',
            color: isActive ? '#933d31' : '',
          })}
          sx={{
            '&:hover': {
              background: '#933d3122',
              borderRadius: '8px',
              color: '#933d31',
            },
            mb: 1,
            pl: 4
          }}
        >
          <ListItemText primary='Ordenes' />
        </ListItem>
        <ListItem button
          component={NavLink}
          to='coupons'
          onClick={handleDrawerToggle}
          style={({ isActive }) => ({
            background: isActive ? '#933d3122' : '',
            borderRadius: isActive ? '8px' : '',
            color: isActive ? '#933d31' : '',
          })}
          sx={{
            '&:hover': {
              background: '#933d3122',
              borderRadius: '8px',
              color: '#933d31',
            },
            mb: 1,
            pl: 4
          }}
        >
          <ListItemText primary='Cupones' />
        </ListItem>
      </List>
      <Box sx={{ marginTop: 'auto', p: 2 }}>
        <Grid container spacing={2}>
          <Grid item xs={12} align='center'>
            <Avatar
              alt='Profile Picture'
              src='/assets/images/rubrica-logo.png'
              sx={{ width: 64, height: 64 }}
            />
          </Grid>
          <Grid item xs={12} align='center'>
            <Typography variant='body2'>
              { user.name }
            </Typography>
            <Typography variant='caption'>
              { user.email }
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Button
              color='primary'
              disableElevation
              fullWidth
              onClick={logout}
              size='small'
              variant='contained'
            >
              Cerrar sesión
            </Button>
          </Grid>
        </Grid>
      </Box>
    </>
  )

  const container = window !== undefined ? () => window().document.body : undefined

  const LinkRouter = (props) => <Link {...props} component={RouterLink} />

  const breadcrumbNameMap = {
    '/admin': 'Inicio',
    '/admin/orders': 'Ordenes',
    '/admin/coupons': 'Cupones',
  }

  const Page = () => {
    const location = useLocation()
    const pathnames = location.pathname.split('/').filter((x) => x)
  
    return (
      <Breadcrumbs 
        aria-label='breadcrumb'
        separator={<NavigateNextIcon fontSize='small' />} 
        sx={{ pb: 2 }}
      >
        <LinkRouter underline='hover' color='inherit' to='/dashboard'>
          Rúbrica
        </LinkRouter>
        {pathnames.map((value, index) => {
          const last = index === pathnames.length - 1
          const to = `/${pathnames.slice(0, index + 1).join('/')}`
  
          return last ? (
            <Typography color='text.primary' key={to}>
              {breadcrumbNameMap[to]}
            </Typography>
          ) : (
            <LinkRouter underline='hover' color='inherit' to={to} key={to}>
              {breadcrumbNameMap[to]}
            </LinkRouter>
          )
        })}
      </Breadcrumbs>
    )
  }

  return (
    <Box sx={{ display: 'flex' }}>
      <AppBar
        color='inherit'
        position='fixed'
        sx={{
          boxShadow: 'none',
          display: { xs: 'flex', md: 'none' },
          ml: `${drawerWidth}px`,
          // width: `calc(100% - ${drawerWidth}px)`,
        }}
      >
        <Toolbar>
          <IconButton
            color='inherit'
            aria-label='open drawer'
            edge='start'
            onClick={handleDrawerToggle}
            sx={{ mr: 2, display: { sm: 'none' } }}
          >
            <MenuIcon />
          </IconButton>
          {/* <Typography variant='h6' noWrap component='div'>
            Rúbrica
          </Typography> */}
          <Box
            component='img'
            src='/assets/images/logo_rubrica_letras.png'
            sx={{ height: 16 }}
          />
          {/* <Box sx={{ flexGrow: 1 }}>
            
          </Box> */}
        </Toolbar>
      </AppBar>
      <Drawer
        // anchor='left'
        container={container}
        ModalProps={{
          keepMounted: true, // Better open performance on mobile.
        }}
        open={mobileOpen}
        onClose={handleDrawerToggle}
        sx={{
          display: { xs: 'block', md: 'none' },
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            borderWidth: 0,
            boxSizing: 'border-box',
            width: drawerWidth
          },
          width: drawerWidth,
        }}
        variant='temporary'
      >
        { drawer }
      </Drawer>
      <Drawer
        sx={{
          display: { xs: 'none', md: 'block' },
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            borderWidth: 0,
            boxSizing: 'border-box',
            width: drawerWidth,
            bgcolor: '#fafafa'
          },
          width: drawerWidth,
        }}
        variant='permanent'
        anchor='left'
      >
        { drawer }
      </Drawer>
      <Box
        component='main'
        sx={{ bgcolor: 'background.default', flexGrow: 1, p: 2 }}
      >
        <Toolbar sx={{ display: { xs: 'flex', md: 'none' } }}/>
        <Page />
        <Routes>
          <Route path='' element={<Home />} />
          <Route path='orders' element={<Orders />} />
          <Route path='coupons' element={<Coupons />} />
        </Routes>
      </Box>
    </Box>
  )
}

export default Admin