import React, { useEffect, useState } from 'react'

import useAuth from 'hooks/auth'

import {
  Box,
  Button,
  Card,
  CardContent,
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  TextField,
  Typography,
} from '@mui/material'

import EditIcon from '@mui/icons-material/Edit'

import { Amex, Mastercard, Visa } from 'react-pay-icons'

import InputMask from 'react-input-mask'

const Payment = (props) => {
  const { onPaymentSelected } = props
  const { openPay } = useAuth()

  useEffect(() => {
    openPay.init()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const cards = {
    'Mastercard': <Mastercard style={{ height: 28 }} />,
    'Visa': <Visa style={{ height: 28 }} />,
    'Visa Electron': <Visa style={{ height: 28 }} />,
    'American Express': <Amex style={{ height: 28 }} />
  }

  const [selectedCreditCard, setSelectedCreditCard] = useState()
  
  const [cardType, setCardType] = useState('')
  const [creditCard, setCreditCard] = useState({})
  const handleCreditCardChange = (prop) => (event) => {
    setCreditCard({ ...creditCard, [prop]: event.target.value })
  }

  useEffect(() => {
    setCardType('')
    if (creditCard['number'] === undefined) return
    const number = creditCard['number']

    if (number.length < 18) return

    const result = openPay.validateCardNumber(number)
    setCardType(result['type'])
  
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [creditCard])
  
  const addCreditCard = () => {
    setSelectedCreditCard(creditCard)
    onPaymentSelected({
      'card': creditCard,
      'type': cardType
    })
  }

  const validateCardDate = () => {
    const creditMonth = creditCard['expire'].split('/')[0]
    const creditYear = creditCard['expire'].split('/')[1]

    if (creditMonth === '' || creditYear === '') return false

    const today = new Date()
    const month = today.getMonth() + 1
    const year = parseInt(today.getFullYear().toString().slice(-2))
    const expireMonth = parseInt(creditMonth)
    const expireYear = parseInt(creditYear)

    if (expireYear < year) return false
    if (expireYear === year && expireMonth <= month) return false

    return true
  }

  const validateCardCVV = () => {
    return cardType === 'American Express' ? creditCard['cvv'].length === 4 : creditCard['cvv'].length === 3
  }

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography variant='h6'>
          <b>Método de pago</b>
        </Typography>
      </Grid>
      {
        selectedCreditCard !== undefined ? (
          <Grid item xs={12}>
            <Card variant='outlined'>
              <CardContent sx={{ p: 2, '&:last-child': { paddingBottom: 2 } }}>
                <Box sx={{ display: 'flex' }} alignItems='center'>
                  <Box sx={{ flexShrink: 0 }}>
                    {cards[cardType]}
                  </Box>
                  <Box sx={{ px: 2, width: '100%' }}>
                    <Typography variant='body2'>
                      •••• •••• •••• {selectedCreditCard['number'].substr(15)}
                    </Typography>
                  </Box>
                  <Box sx={{ flexShrink: 0 }}>
                    <IconButton onClick={() => setSelectedCreditCard(undefined)}>
                      <EditIcon />
                    </IconButton>
                  </Box>
                </Box>
              </CardContent>
            </Card>
          </Grid>
        ) : null
      }
      {
        selectedCreditCard === undefined ? (
          <Grid item xs={12}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <FormControl fullWidth variant='outlined'>
                  <InputLabel htmlFor='credit-card-number'>Número de tarjeta</InputLabel>
                  <InputMask
                    mask='9999 9999 9999 9999'
                    maskChar=''
                    value={creditCard['number']}
                    onChange={handleCreditCardChange('number')}
                  >
                    { (inputProps) => 
                      <OutlinedInput 
                        {...inputProps} 
                        id='credit-card-number' 
                        label='Número de tarjeta' 
                        name='number'
                        endAdornment={
                          <InputAdornment position='end'>
                            {cards[cardType]}
                          </InputAdornment>
                        }
                      /> 
                    }
                  </InputMask>
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  // error={errorEmail}
                  // helperText={errorEmailText}
                  InputLabelProps={{ shrink: true }}
                  label='Nombre del titular'
                  value={creditCard['name']}
                  onChange={handleCreditCardChange('name')}
                  placeholder='Como aparece en la tarjeta'
                  fullWidth
                />
              </Grid>
              <Grid item xs={6}>
                <FormControl fullWidth variant='outlined'>
                  <InputLabel htmlFor='credit-card-date'>Fecha de expiración</InputLabel>
                  <InputMask
                    mask='99/99'
                    maskChar=''
                    value={creditCard['expire']}
                    onChange={handleCreditCardChange('expire')}
                  >
                    { (inputProps) => <OutlinedInput placeholder='MM/YY' {...inputProps} id='credit-card-date' label='Fecha de expiración' name='number' /> }
                  </InputMask>
                </FormControl>
              </Grid>
              <Grid item xs={6}>
                <FormControl fullWidth variant='outlined'>
                  <InputLabel htmlFor='credit-card-cvv'>Código de seguridad</InputLabel>
                  <InputMask
                    mask={cardType === 'American Express' ? '9999' : '999'}
                    maskChar=''
                    value={creditCard['cvv']}
                    onChange={handleCreditCardChange('cvv')}
                  >
                    { (inputProps) => <OutlinedInput placeholder={`${cardType === 'American Express' ? '4' : '3'} dígitos`} {...inputProps} id='credit-card-cvv' label='Código de seguridad' name='number' /> }
                  </InputMask>
                </FormControl>
              </Grid>
              <Grid item xs={12} align='right'>
                <Button disabled={ creditCard['name'] === undefined || creditCard['number'] === undefined || creditCard['expire'] === undefined || creditCard['cvv'] === undefined || !validateCardCVV() || !validateCardDate()} disableElevation variant='contained' size='large' onClick={addCreditCard}>
                  <b>Validar</b>
                </Button>
              </Grid>
            </Grid>
          </Grid>
        ) : null
      }
    </Grid>
  )
}

export default Payment