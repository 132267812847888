import React, { useState } from 'react'

import {
  FormControl,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
} from '@mui/material'

const Quantity = (props) => {
  const { current, getCurrentValue } = props

  const values = {
    'quantity_1': 4,
    'quantity_2': 6,
    'quantity_3': 8,
    'quantity_4': 10,
  }
  
  const [value, setValue] = useState(current !== undefined ? current.id : '')

  const handleRadioChange = (event) => {
    setValue(event.target.value)
    getCurrentValue({
      'id': event.target.value,
      'value': values[event.target.value],
    })
  }

  return (
    <Grid container alignContent='center' alignItems='center' direction='column' justifyContent='center'>
      <Grid item xs={12}>
        <FormControl>
          <RadioGroup value={value} onChange={handleRadioChange}>
            <FormControlLabel value='quantity_1' control={<Radio />} label='1 taza' />
            <FormControlLabel value='quantity_2' control={<Radio />} label='2 tazas' />
            <FormControlLabel value='quantity_3' control={<Radio />} label='3 tazas' />
            <FormControlLabel value='quantity_4' control={<Radio />} label='+3 tazas' />
          </RadioGroup>
        </FormControl>
      </Grid>
    </Grid>
    
  )
}

export default Quantity