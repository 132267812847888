import {
  getFunctions,
  httpsCallable,
} from 'firebase/functions'

import app from 'config/firebase'

const functions = getFunctions(app)

export const useProvideFunctions = () => {
  const getShipping = async (code) => {
    const shippingFunction = httpsCallable(functions, 'shipping')
    const result = await shippingFunction({ 'code': code })
    console.log(JSON.stringify(result))
    return result.data
  }

  const requestShipment = async (order) => {
    const documentShipmentFunction = httpsCallable(functions, 'documentShipment')
    const result = await documentShipmentFunction({ 'order': order })
    return result.data
  }

  const createCharge = async (data) => {
    const createChargeFunction = httpsCallable(functions, 'createCharge')
    try {
      const result = await createChargeFunction(data)
      return result.data
    } catch(error) {
      console.log(error)
      return null
    }
  }

  const createSubscription = async (data) => {
    const createSubscriptionFunction = httpsCallable(functions, 'createSubscription')
    const result = await createSubscriptionFunction(data)
    return result.data
  }

  const sendEmail = async (data) => {
    const sendEmailFunction = httpsCallable(functions, 'sendEmail')
    const result = await sendEmailFunction(data)
    return result.data
  }

  return {
    createCharge,
    createSubscription,
    getShipping,
    requestShipment,
    sendEmail,
  }
}