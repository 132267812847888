export const useFacturama = () => {

  const init = () => {
    window.valuesFacturama = {
      token: process.env.REACT_APP_FACTURAMA_TOKEN,
      url: 'https://api.facturama.mx/3/cfdis'
    }
  }

  const requestInvoice = (receiver, item) => {
    init()

    var newCfdi = {
      'Receiver': {
        'Name': receiver['name'],
        'CfdiUse': receiver['use'],
        'Rfc': receiver['rfc'],
        'Email': receiver['email'],
        'FiscalRegime': receiver['regimen'],
        'TaxZipCode': receiver['code']
      },
      'CfdiType': 'I',
      'NameId': '1',
      'ExpeditionPlace': '10130',
      'PaymentForm': receiver['payment'], // 04 credito 28 debito
      'PaymentMethod': 'PUE',
      'Items': [ 
        {
          'Quantity': parseInt(item['qty']),
          'ProductCode': '50201706',
          'UnitCode': 'E48',
          'Description': item['description'],
          'IdentificationNumber': item['id'],
          'UnitPrice': item['unit'],
          'Subtotal': item['unit'] * item['qty'],
          'TaxObject': '01',
          'Total': item['unit'] * item['qty']
        },{
        'Quantity': 1,
        'ProductCode': '78101800',
        'UnitCode': 'E48',
        'Description': 'Envío',
        'IdentificationNumber': item['id'],
        'UnitPrice': item['shipping'],
        'Subtotal': item['shipping'],
        'Taxes': [{
          'Name': 'IVA',
          'Rate': '0.16',
          'Total': +(item['shipping'] * 0.16).toFixed(2),
          'Base': item['shipping'],
          'IsRetention': 'false'
        }],
        'TaxObject': '02',
        'Total': +(item['shipping'] * 1.16).toFixed(2)
      }]
    }

    console.log(newCfdi)
    return new Promise((resolve, reject) => {
      window.Facturama.Cfdi.Create3(newCfdi, function (result) {
        var cfdi = result
        console.log('creacion de una factura', result)
        resolve(result)

        //descargar el PDF del cfdi
        // window.Facturama.Cfdi.Download('pdf', 'issued', cfdi.Id, function(result){
        //   console.log('Descarga', result)

        //   // blob = converBase64toBlob(result.Content, 'application/pdf')

        //   // var blobURL = URL.createObjectURL(blob)
        //   // window.open(blobURL)
        // })

        //descargar el XML del cfdi
        // window.Facturama.Cfdi.Download('xml', 'issued', cfdi.Id, function(result){
        //   console.log('Descarga',result)

        //   // blob = converBase64toBlob(result.Content, 'application/xml')

        //   // var blobURL = URL.createObjectURL(blob)
        //   // window.open(blobURL)
        // })

        //enviar el cfdi al cliente
            //console.log('Id del la factura: ',Cfdi_Id);
        window.Facturama.Cfdi.Send('?cfdiType=issued&cfdiId=' + cfdi.Id + '&email=' + receiver['email'], function(result){ 
          console.log('envio', result);
        })
      }, function (error) {
        console.log(error)
        if (error && error.responseJSON) {
          console.log(error.responseJSON)
        }
        reject(error.responseJSON['Message'])
      })
    })
    
  }

  return {
    requestInvoice
  }
}