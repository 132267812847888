import React, { useState } from 'react'

import {
  FormControl,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
} from '@mui/material'

const Drink = (props) => {
  const { current, getCurrentValue } = props

  const values = {
    'drink_1': 5,
    'drink_2': 4.5,
    'drink_3': 4,
    'drink_4': 3.5,
    'drink_5': 3,
    'drink_6': 2,
    'drink_7': 1,
    'drink_8': 0,
  }
  
  const [value, setValue] = useState(current !== undefined ? current.id : '')

  const handleRadioChange = (event) => {
    setValue(event.target.value)
    getCurrentValue({
      'id': event.target.value,
      'value': values[event.target.value],
    })
  }

  return (
    <Grid container alignContent='center' alignItems='center' direction='column' justifyContent='center'>
      <Grid item xs={12}>
        <FormControl>
          <RadioGroup value={value} onChange={handleRadioChange}>
            <FormControlLabel value='drink_1' control={<Radio />} label='Kahlúa, Copa de oro, Camora' />
            <FormControlLabel value='drink_2' control={<Radio />} label='Angostura, Benedictine, Calisay' />
            <FormControlLabel value='drink_3' control={<Radio />} label='Amaretto, Madeira, Baileys' />
            <FormControlLabel value='drink_4' control={<Radio />} label='Cointreau, Grand Marnier, Limoncello' />
            <FormControlLabel value='drink_5' control={<Radio />} label='Pacharán, Oporto, Jerez' />
            <FormControlLabel value='drink_6' control={<Radio />} label='Cognac, Brandy' />
            <FormControlLabel value='drink_7' control={<Radio />} label='Marsala, Anís, Sambuca' />
            <FormControlLabel value='drink_8' control={<Radio />} label='No bebo' />
          </RadioGroup>
        </FormControl>
      </Grid>
    </Grid>
    
  )
}

export default Drink