import React, { useState } from 'react'

import {
  FormControl,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
} from '@mui/material'

const Wine = (props) => {
  const { current, getCurrentValue } = props

  const values = {
    'wine_1': 5,
    'wine_2': 4.5,
    'wine_3': 4,
    'wine_4': 3.5,
    'wine_5': 3,
    'wine_6': 2,
    'wine_7': 1,
    'wine_8': 0,
  }
  
  const [value, setValue] = useState(current !== undefined ? current.id : '')

  const handleRadioChange = (event) => {
    setValue(event.target.value)
    getCurrentValue({
      'id': event.target.value,
      'value': values[event.target.value],
    })
  }

  return (
    <Grid container alignContent='center' alignItems='center' direction='column' justifyContent='center'>
      <Grid item xs={12}>
        <FormControl>
          <RadioGroup value={value} onChange={handleRadioChange}>
            <FormControlLabel value='wine_1' control={<Radio />} label='Merlot/Zinfandel' />
            <FormControlLabel value='wine_2' control={<Radio />} label='Syrah/Grenache' />
            <FormControlLabel value='wine_3' control={<Radio />} label='Cabernet Sauvignon' />
            <FormControlLabel value='wine_4' control={<Radio />} label='Malbec/Bonarda' />
            <FormControlLabel value='wine_5' control={<Radio />} label='Marselan/Sangiovese/Nebbiolo' />
            <FormControlLabel value='wine_6' control={<Radio />} label='Cabernet Franc' />
            <FormControlLabel value='wine_7' control={<Radio />} label='Tannat/Pinot Noir' />
            <FormControlLabel value='wine_8' control={<Radio />} label='No tomo vino' />
          </RadioGroup>
        </FormControl>
      </Grid>
    </Grid>
    
  )
}

export default Wine