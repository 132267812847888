import React from 'react'

import {
  Grid,
  Typography
} from '@mui/material'

const Result = (props) => {
  const { rubrica } = props

  return (
    <Grid container align='center'>
      <Grid item xs={12}>
        <Typography variant='body1'>
          <b>Varietal:</b> {rubrica.varietal}
        </Typography>
        <Typography variant='body1'>
          <b>Proceso:</b> {rubrica.proceso}
        </Typography>
        <Typography variant='body1'>
          <b>Grupo aromático:</b> {rubrica.aromatico}
        </Typography>
        <Typography variant='body1'>
          <b>Notas específicas:</b> {rubrica.notas}
        </Typography>
        <Typography variant='body1'>
          <b>Origen:</b> {rubrica.origen}
        </Typography>
        <Typography variant='body1'>
          <b>Cantidad:</b> 340 grs de café en grano {rubrica.grano}
        </Typography>
      </Grid>
    </Grid>
  )
}

export default Result