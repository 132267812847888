
const Logo = (props) => {

  const { blue, orange, red, yellow, final } = props

  return (
    <svg
      width='100%'
      height='100%'
      viewBox='0 0 100 100'
      stroke='#9c8500'
      // strokeOpacity='0.7'
      fill='none'
      {...props}
    >
      <line x1='8' y1='52' x2='8' y2='48' stroke='#d4d5d6' strokeOpacity={final === undefined ? 0.7 : 0 } strokeWidth='0.5' />
      <line x1='12' y1='52' x2='12' y2='48' stroke='#d4d5d6' strokeOpacity={final === undefined ? 0.7 : 0 } strokeWidth='0.5' />
      <line x1='16' y1='52' x2='16' y2='48' stroke='#d4d5d6' strokeOpacity={final === undefined ? 0.7 : 0 } strokeWidth='0.5' />
      <line x1='20' y1='52' x2='20' y2='48' stroke='#d4d5d6' strokeOpacity={final === undefined ? 0.7 : 0 } strokeWidth='0.5' />
      <line x1='24' y1='52' x2='24' y2='48' stroke='#d4d5d6' strokeOpacity={final === undefined ? 0.7 : 0 } strokeWidth='0.5' />
      <line x1='28' y1='52' x2='28' y2='48' stroke='#d4d5d6' strokeOpacity={final === undefined ? 0.7 : 0 } strokeWidth='0.5' />

      <line x1='72' y1='52' x2='72' y2='48' stroke='#d4d5d6' strokeOpacity={final === undefined ? 0.7 : 0 } strokeWidth='0.5' />
      <line x1='76' y1='52' x2='76' y2='48' stroke='#d4d5d6' strokeOpacity={final === undefined ? 0.7 : 0 } strokeWidth='0.5' />
      <line x1='80' y1='52' x2='80' y2='48' stroke='#d4d5d6' strokeOpacity={final === undefined ? 0.7 : 0 } strokeWidth='0.5' />
      <line x1='84' y1='52' x2='84' y2='48' stroke='#d4d5d6' strokeOpacity={final === undefined ? 0.7 : 0 } strokeWidth='0.5' />
      <line x1='88' y1='52' x2='88' y2='48' stroke='#d4d5d6' strokeOpacity={final === undefined ? 0.7 : 0 } strokeWidth='0.5' />
      <line x1='92' y1='52' x2='92' y2='48' stroke='#d4d5d6' strokeOpacity={final === undefined ? 0.7 : 0 } strokeWidth='0.5' />

      <line y1='8' x1='52' y2='8' x2='48' stroke='#d4d5d6' strokeOpacity={final === undefined ? 0.7 : 0 } strokeWidth='0.5' />
      <line y1='12' x1='52' y2='12' x2='48' stroke='#d4d5d6' strokeOpacity={final === undefined ? 0.7 : 0 } strokeWidth='0.5' />
      <line y1='16' x1='52' y2='16' x2='48' stroke='#d4d5d6' strokeOpacity={final === undefined ? 0.7 : 0 } strokeWidth='0.5' />
      <line y1='20' x1='52' y2='20' x2='48' stroke='#d4d5d6' strokeOpacity={final === undefined ? 0.7 : 0 } strokeWidth='0.5' />
      <line y1='24' x1='52' y2='24' x2='48' stroke='#d4d5d6' strokeOpacity={final === undefined ? 0.7 : 0 } strokeWidth='0.5' />
      <line y1='28' x1='52' y2='28' x2='48' stroke='#d4d5d6' strokeOpacity={final === undefined ? 0.7 : 0 } strokeWidth='0.5' />

      <line y1='72' x1='52' y2='72' x2='48' stroke='#d4d5d6' strokeOpacity={final === undefined ? 0.7 : 0 } strokeWidth='0.5' />
      <line y1='76' x1='52' y2='76' x2='48' stroke='#d4d5d6' strokeOpacity={final === undefined ? 0.7 : 0 } strokeWidth='0.5' />
      <line y1='80' x1='52' y2='80' x2='48' stroke='#d4d5d6' strokeOpacity={final === undefined ? 0.7 : 0 } strokeWidth='0.5' />
      <line y1='84' x1='52' y2='84' x2='48' stroke='#d4d5d6' strokeOpacity={final === undefined ? 0.7 : 0 } strokeWidth='0.5' />
      <line y1='88' x1='52' y2='88' x2='48' stroke='#d4d5d6' strokeOpacity={final === undefined ? 0.7 : 0 } strokeWidth='0.5' />
      <line y1='92' x1='52' y2='92' x2='48' stroke='#d4d5d6' strokeOpacity={final === undefined ? 0.7 : 0 } strokeWidth='0.5' />
      
      <circle cx='50' cy='64' r='32' stroke='#d4d5d6' strokeOpacity={final === undefined ? 0.7 : 0 } strokeWidth='0.8' />
      <circle cx='50' cy='36' r='32' stroke='#d4d5d6' strokeOpacity={final === undefined ? 0.7 : 0 } strokeWidth='0.8' />
      <circle cx='36' cy='50' r='32' stroke='#d4d5d6' strokeOpacity={final === undefined ? 0.7 : 0 } strokeWidth='0.8' />
      <circle cx='64' cy='50' r='32' stroke='#d4d5d6' strokeOpacity={final === undefined ? 0.7 : 0 } strokeWidth='0.8' />

      <circle cx='50' cy='64' r={yellow} fill={'rgba(203, 180, 45, 1)'} style={{ mixBlendMode: 'multiply' }} /> {/* Yellow */}
      <circle cx='64' cy='50' r={orange} fill={'rgba(205, 150, 70, 1)'} style={{ mixBlendMode: 'multiply' }} /> {/* Orange */}
      <circle cx='50' cy='36' r={blue} fill={'rgba(96, 128, 128, 1)'} style={{ mixBlendMode: 'multiply' }} /> {/* Blue */}
      <circle cx='36' cy='50' r={red} fill={'rgba(145, 61, 49, 1)'} style={{ mixBlendMode: 'multiply' }} /> {/* Red */}
    </svg>
  )
}

export default Logo
