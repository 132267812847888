import React, { useState } from 'react'

import {
  Button,
  Card,
  CardContent,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  TextField,
  Typography,
} from '@mui/material'

import EditIcon from '@mui/icons-material/Edit'

import InputMask from 'react-input-mask'

import { MX } from 'country-region-data/dist/data.js'

const Address = (props) => {

  const { onAddressSelected } = props

  const [selectedAddress, setSelectedAddress] = useState()

  const [address, setAddress] = useState({})
  const handleAddressChange = (prop) => (event) => {
    setAddress({ ...address, [prop]: event.target.value })
  }

  const [state, setState] = useState('Any')
  const handleStateChange = (event) => {
    const value = event.target.value
    setState(value)
    const selectedReg = MX[2].filter((reg) => reg[1] === value)[0][0] === 'Querétaro de Arteaga' ? 'Querétaro' : MX[2].filter((reg) => reg[1] === value)[0][0]
    setAddress({ ...address, 'state': selectedReg })
  }

  const onSaveAddress = () => {
    setSelectedAddress(address)
    onAddressSelected(address)
  }

  const onEditAddress = () => {
    setSelectedAddress(undefined)
    onAddressSelected(undefined)
  }

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography variant='h6'>
          <b>Información de envío</b>
        </Typography>
      </Grid>
      {
        selectedAddress !== undefined ? (
          <Grid item xs={12}>
            <Card variant='outlined'>
              <CardContent sx={{ p: 2, '&:last-child': { paddingBottom: 2 } }}>
                <Grid container spacing={2} alignItems='center'>
                  <Grid item xs={10}>
                  <Typography variant='body2'>
                      <b>{selectedAddress['name']}</b>
                    </Typography>
                    <Typography variant='body2'>
                      {selectedAddress['street']} {selectedAddress['outside']}, {selectedAddress['neighborhood']}. {selectedAddress['others']}
                    </Typography>
                    <Typography variant='body2'>
                      {selectedAddress['city']}, {MX[2].filter((reg) => reg[1] === state)[0][0] === 'Querétaro de Arteaga' ? 'Querétaro' : MX[2].filter((reg) => reg[1] === state)[0][0]}. C.P. {selectedAddress['code']}
                    </Typography>
                  </Grid>
                  <Grid item xs={2} align='right'>
                    <IconButton onClick={onEditAddress}>
                      <EditIcon />
                    </IconButton>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
        ) : null
      }
      {
        selectedAddress === undefined ? (
          <Grid item xs={12}>
            <Grid container spacing={2}>
            <Grid item xs={12}>
                <TextField
                  // error={errorEmail}
                  // helperText={errorEmailText}
                  InputLabelProps={{ shrink: true }}
                  label='Nombre *'
                  value={address['name']}
                  onChange={handleAddressChange('name')}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  // error={errorEmail}
                  // helperText={errorEmailText}
                  InputLabelProps={{ shrink: true }}
                  label='Calle *'
                  value={address['street']}
                  onChange={handleAddressChange('street')}
                  fullWidth
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  // error={errorEmail}
                  // helperText={errorEmailText}
                  InputLabelProps={{ shrink: true }}
                  label='Número exterior *'
                  value={address['outside']}
                  onChange={handleAddressChange('outside')}
                  fullWidth
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  // error={errorEmail}
                  // helperText={errorEmailText}
                  InputLabelProps={{ shrink: true }}
                  label='Número interior'
                  value={address['inside']}
                  onChange={handleAddressChange('inside')}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  // error={errorEmail}
                  // helperText={errorEmailText}
                  InputLabelProps={{ shrink: true }}
                  label='Colonia *'
                  value={address['neighborhood']}
                  onChange={handleAddressChange('neighborhood')}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  // error={errorEmail}
                  // helperText={errorEmailText}
                  InputLabelProps={{ shrink: true }}
                  label='Información adicional'
                  value={address['others']}
                  onChange={handleAddressChange('others')}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  // error={errorEmail}
                  // helperText={errorEmailText}
                  InputLabelProps={{ shrink: true }}
                  label='Delegación o municipio *'
                  value={address['city']}
                  onChange={handleAddressChange('city')}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <FormControl fullWidth variant='outlined'>
                  <InputLabel shrink id='select-outlined-city'>Estado *</InputLabel>
                  <Select
                    labelId='select-outlined-city'
                    id='select-outlined-city'
                    onChange={handleStateChange}
                    value={state}
                    label='Estado *'
                    notched
                  >
                    <MenuItem value='Any'>
                      <em></em>
                    </MenuItem>
                    { 
                      MX[2].map(city =>
                        <MenuItem key={city[0]} value={city[1]}>
                          {city[0] === 'Querétaro de Arteaga' ? 'Querétaro' : city[0]}
                        </MenuItem>
                      )
                    }
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} md={6}>
                <FormControl fullWidth variant='outlined'>
                  <InputLabel shrink htmlFor='postal-code'>Código Postal *</InputLabel>
                  <InputMask
                    mask='99999'
                    maskChar=''
                    value={address['code']}
                    onChange={handleAddressChange('code')}
                  >
                    { (inputProps) => <OutlinedInput {...inputProps} id='postal-code' label='Código Postal *' name='number' notched /> }
                  </InputMask>
                </FormControl>
              </Grid>
              <Grid item xs={12} align='right'>
                <Button disabled={address['name'] === undefined || address['street'] === undefined || address['outside'] === undefined || address['neighborhood'] === undefined || address['city'] === undefined || address['state'] === undefined || address['code'] === undefined} disableElevation variant='contained' size='large' onClick={onSaveAddress}>
                  <b>Validar</b>
                </Button>
              </Grid>
            </Grid>
          </Grid>
        ) : null
      }
    </Grid>
  )
}

export default Address