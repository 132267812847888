import React, { useState } from 'react'

import {
  FormControl,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
} from '@mui/material'

const Sugar = (props) => {
  const { current, getCurrentValue } = props

  const values = {
    'sugar_1': 2,
    'sugar_2': 1,
  }

  const [value, setValue] = useState(current !== undefined ? current.id : '')

  const handleRadioChange = (event) => {
    setValue(event.target.value)
    getCurrentValue({
      'id': event.target.value,
      'value': values[event.target.value],
    })
  }

  return (
    <Grid container alignContent='center' alignItems='center' direction='column' justifyContent='center'>
      <Grid item xs={12}>
        <FormControl>
          <RadioGroup row value={value} onChange={handleRadioChange}>
            <FormControlLabel value='sugar_1' control={<Radio />} label='Nunca' />
            <FormControlLabel value='sugar_2' control={<Radio />} label='Depende, pero generalmente si' />
          </RadioGroup>
        </FormControl>
      </Grid>
    </Grid>
  )
}

export default Sugar