import React from 'react'

import {
  Grid,
} from '@mui/material'

const Home = (props) => {
  return (
    <Grid container spacing={2} alignItems='center'>
    </Grid>
  )
}

export default Home