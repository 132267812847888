import React, { useEffect, useState } from 'react'

import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
} from '@mui/material'

const Recipe = (props) => {
  const { current, getCurrentValue } = props

  const [state, setState] = useState(current !== undefined ? current : {
    recipe_1: false, recipe_2: false, recipe_3: false, recipe_4: false, recipe_5: false, recipe_6: false, recipe_7: false, recipe_8: false, recipe_9: false, recipe_10: false, recipe_11: false, recipe_12: false, recipe_13: false
  })

  const {
    recipe_1, recipe_2, recipe_3, recipe_4, recipe_5, recipe_6, recipe_7, recipe_8, recipe_9, recipe_10, recipe_11, recipe_12, recipe_13,
  } = state

  const error = [recipe_1, recipe_2, recipe_3, recipe_4, recipe_5, recipe_6, recipe_7, recipe_8, recipe_9, recipe_10, recipe_11, recipe_12, recipe_13].filter((v) => v).length !== 4

  const handleChange = (event) => {
    if (error || !event.target.checked) {
      setState({
        ...state,
        [event.target.name]: event.target.checked,
      })
    }
  }

  useEffect(() => {
    getCurrentValue(state)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state])

  return (
    <Grid container alignContent='center'  justifyContent='center'>
      <Grid item xs={12} md={6}>
        <FormControl fullWidth>
          <FormGroup>
            <FormControlLabel control={<Checkbox name='recipe_1' checked={recipe_1} onChange={handleChange} />} label='Ristretto' />
            <FormControlLabel control={<Checkbox name='recipe_2' checked={recipe_2} onChange={handleChange} />} label='Ristretto con leche' />
            <FormControlLabel control={<Checkbox name='recipe_3' checked={recipe_3} onChange={handleChange} />} label='Espresso Sencillo con leche' />
            <FormControlLabel control={<Checkbox name='recipe_4' checked={recipe_4} onChange={handleChange} />} label='Espresso Sencillo' />
            <FormControlLabel control={<Checkbox name='recipe_5' checked={recipe_5} onChange={handleChange} />} label='Espresso Doble Corto Negro' />
            <FormControlLabel control={<Checkbox name='recipe_6' checked={recipe_6} onChange={handleChange} />} label='Espresso Macchiato' />
            <FormControlLabel control={<Checkbox name='recipe_7' checked={recipe_7} onChange={handleChange} />} label='Lungo' />
          </FormGroup>
        </FormControl>
      </Grid>
      <Grid item xs={12} md={6}>
        <FormControl fullWidth>
          <FormGroup>
            <FormControlLabel control={<Checkbox name='recipe_8' checked={recipe_8} onChange={handleChange} />} label='Lungo con leche' />
            <FormControlLabel control={<Checkbox name='recipe_9' checked={recipe_9} onChange={handleChange} />} label='Cappuccino' />
            <FormControlLabel control={<Checkbox name='recipe_10' checked={recipe_10} onChange={handleChange} />} label='Flat White' />
            <FormControlLabel control={<Checkbox name='recipe_11' checked={recipe_11} onChange={handleChange} />} label='Drip' />
            <FormControlLabel control={<Checkbox name='recipe_12' checked={recipe_12} onChange={handleChange} />} label='Americano' />
            <FormControlLabel control={<Checkbox name='recipe_13' checked={recipe_13} onChange={handleChange} />} label='Americano con leche' />
          </FormGroup>
        </FormControl>
      </Grid>
    </Grid>
    
  )
}

export default Recipe
