import React, { useState } from 'react'

import { useNavigate } from 'react-router-dom'

import useAuth from 'hooks/auth'

import {
  Button,
  Dialog,
  DialogContent,
  Grid,
  TextField,
  Typography,
} from '@mui/material'
import Label from 'pages/label'

import GoogleIcon from '@mui/icons-material/Google'
import SignIn from 'pages/account/signin'
import SignUp from 'pages/account/signup'
// import Mail from '@mui/icons-material/Mail'

const Summary = (props) => {
  const { data } = props
  
  const navigate = useNavigate()
  const { auth, firestore } = useAuth()

  const { credentials, user } = auth

  const url = window.location.href

  const [newName, setNewName] = useState('')
  const handleNameChange = (event) => {
    setNewName(event.target.value)
  }

  const googleSignIn = async () => {
    const uid = await auth.signInWithGoogle()
    data['name'] = newName
    await firestore.saveRubrica(uid, data)
  }

  const onProceed = () => {
    if (credentials !== null) {
      save()
    }

    data['name'] = newName
    localStorage.setItem('data', JSON.stringify(data))
    navigate('/checkout')
  }
  
  const save = async () => {
    const uid = credentials.uid
    data['name'] = newName
    await firestore.saveRubrica(uid, data)
  }

  const onSave = () => {
    save()

    if (url.includes('dashboard')) {
      navigate('/dashboard')
    }
  }

  const [openSignIn, setOpenSignIn] = useState(false)
  const [openSignUp, setOpenSignUp] = useState(false)

  const handleClose = () => {
    setOpenSignIn(false)
    setOpenSignUp(false)
  }

  const header = (
    <Grid item xs={12}>
      <Grid container spacing={2} alignItems='center'>
        <Grid item xs={12} md={6} lg={8}>
          <Typography variant='h6' gutterBottom>
            {`¡Hola${credentials !== null && user !== undefined ? `, ${user.name}!` : '!'}`}
          </Typography>
          <Typography variant='body1' gutterBottom>
            {/* { url.includes('dashboard') ? 'No olvides guardar los cambios en tu rúbrica' : 'Hemos guardado la rúbrica en tu perfil, ahora puedes continuar con tu compra o visitar tu dashboard.' } */}
            No olvides guardar los cambios en tu rúbrica
          </Typography>
        </Grid>
        <Grid item xs={12} md={6} lg={4} align='right'>
          <Button disabled={newName === ''} fullWidth disableElevation variant='contained' size='large' style={{ color: 'white' }} onClick={onSave}>
            Guardar
          </Button>
          {/* <Button fullWidth disableElevation variant='contained' size='large' style={{ color: 'white' }} onClick={onNavigate}>
            Mi dashboard
          </Button> */}
        </Grid>
      </Grid>
    </Grid>
  )

  const form = (
    <Grid item xs={12}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography variant='h6' gutterBottom>
            <i>¿Tienes una cuenta?</i>
          </Typography>
          <Typography variant='body1'>
            {/* Considera iniciar sesión o regístrarte para no perder tu rúbrica. */}
            Al registrarte guardamos tu información, para que en tu siguiente compra, solo inicies sesión y tu café personalizado esté listo.
          </Typography>
        </Grid>
        {/* <Grid item xs={12} lg={6}>
          <Button 
            disableElevation
            fullWidth
            size='large'
            startIcon={<Mail />}
            sx={{ background: '#373f470a', color: '#444' }}
          >
            Continuar con Correo
          </Button>
        </Grid> */}
        <Grid item xs={6}>
          <Button
            color='rubricaBlue'
            variant='contained'
            disabled={newName === ''}
            disableElevation
            fullWidth
            size='large'
            sx={{ borderRadius: 8 }}
            onClick={() => setOpenSignIn(true)}
          >
            Inicia sesión
          </Button>
        </Grid>
        <Grid item xs={6}>
          <Button
            color='rubricaYellow'
            variant='contained'
            disabled={newName === ''}
            disableElevation
            fullWidth
            size='large'
            sx={{ borderRadius: 8 }}
            onClick={() => setOpenSignUp(true)}
          >
            Regístrate
          </Button>
        </Grid>
        <Grid item xs={12}>
          <Button
            disabled={newName === ''}
            disableElevation
            fullWidth
            size='large'
            startIcon={<GoogleIcon />}
            sx={{ background: '#373f470a', borderRadius: 8, color: '#444' }}
            onClick={() => googleSignIn()}
          >
            Continuar con Google
          </Button>
        </Grid>
      </Grid>
    </Grid>
  )

  return(
    <Grid container spacing={8} alignItems='center'>
      <Grid item xs={12} sx={{ display: { xs: 'block', md: 'none' } }}>
        <Typography variant='h5' gutterBottom>
          <b>¡Tu rúbrica está casi lista!</b>
        </Typography>
        <Typography variant='body1' gutterBottom sx={{ mb: 2 }}>
          Es momento de nombrar tu rúbrica.
        </Typography>
        <TextField label='Nombra tu café:' variant='outlined' fullWidth onChange={handleNameChange} value={newName} />
      </Grid>
      <Grid item xs={12} md={6}>
        <Label newName={newName} data={data} />
      </Grid>
      <Grid item xs={12} md={6}>
        <Grid container spacing={6}>
          <Grid item xs={12} sx={{ display: { xs: 'none', md: 'block' } }}>
            <Typography variant='h5' gutterBottom>
              <b>¡Tu rúbrica está casi lista!</b>
            </Typography>
            <Typography variant='body1' gutterBottom sx={{ mb: 2 }}>
              Es momento de nombrar tu rúbrica.
            </Typography>
            <TextField label='Nombra tu café:' variant='outlined' fullWidth onChange={handleNameChange} value={newName} />
          </Grid>
          <Grid item xs={12}>
            <Typography variant='h5' gutterBottom>
              <b>Acerca de...</b>
            </Typography>
            <Typography variant='body1' gutterBottom>
              Menos del 10% de todo el café que se produce es considerado un café de especialidad, donde el compromiso con la calidad se vigila meticulosamente en cada etapa.
            </Typography>
            <Typography variant='body1'>
              Un productor con ese nivel de atención al detalle merece un pago justo y superior. Tu compra garantiza ese pago y llega, sin intermediarios, a manos de nuestros productores avalados.
            </Typography>
          </Grid>
          {credentials !== null ? header : null}
          {credentials === null ? form : null}
          <Grid item xs={12}>
            <Button disabled={newName === ''} fullWidth disableElevation variant='contained' size='large' onClick={onProceed} sx={{ borderRadius: 8 }}>
              Continuar con la compra
            </Button>
          </Grid>
        </Grid>
      </Grid>
      <Dialog open={openSignIn} onClose={handleClose}>
        <DialogContent sx={{ p: 0 }}>
          <SignIn fromPopup={true} onHandleClose={handleClose} />
        </DialogContent>
      </Dialog>
      <Dialog open={openSignUp} onClose={handleClose}>
        <DialogContent sx={{ p: 0 }}>
          <SignUp fromPopup={true} onHandleClose={handleClose} />
        </DialogContent>
      </Dialog>
    </Grid>
  )
}

export default Summary