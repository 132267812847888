import React, { useEffect, useState } from 'react'

import { useNavigate } from 'react-router-dom'

import useAuth from 'hooks/auth'

import {
  Alert,
  Box,
  Backdrop,
  Button,
  Checkbox,
  CircularProgress,
  Divider,
  FormControlLabel,
  Grid,
  TextField,
  Typography,
} from '@mui/material'

import Payment from './payment'
import Address from './address'

// const errorCodes = {
//   '1000': 'Ocurrió un error interno en el servidor de Openpay',
//   '1001': 'El formato de la petición no es JSON, los campos no tienen el formato correcto, o la petición no tiene campos que son requeridos.',
//   '1002': 'La llamada no esta autenticada o la autenticación es incorrecta.',
//   '1003': 'La operación no se pudo completar por que el valor de uno o más de los parametros no es correcto.',
//   '1004': 'Un servicio necesario para el procesamiento de la transacción no se encuentra disponible.',
//   '1005': 'Uno de los recursos requeridos no existe.',
//   '1006': 'Ya existe una transacción con el mismo ID de orden.',
//   '1007': 'La transferencia de fondos entre una cuenta de banco o tarjeta y la cuenta de Openpay no fue aceptada.',
//   '1008': 'Una de las cuentas requeridas en la petición se encuentra desactivada.',
//   '1009': 'El cuerpo de la petición es demasiado grande.',
//   '1010': 'Se esta utilizando la llave pública para hacer una llamada que requiere la llave privada, o bien, se esta usando la llave privada desde JavaScript.',
//   '2004': 'El número de tarjeta es invalido.',
//   '2005': 'La fecha de expiración de la tarjeta es anterior a la fecha actual.',
//   '2006': 'El código de seguridad de la tarjeta (CVV2) no fue proporcionado.',
//   '2007': 'El número de tarjeta es de prueba, solamente puede usarse en Sandbox.',
//   '2008': 'La tarjeta no es valida para pago con puntos.',
//   '2009': 'El código de seguridad de la tarjeta (CVV2) es inválido.',
//   '2010': 'Autenticación 3D Secure fallida.',
//   '2011': 'Tipo de tarjeta no soportada.',
//   '3001': 'La tarjeta fue declinada por el banco.',
//   '3002': 'La tarjeta ha expirado.',
//   '3003': 'La tarjeta no tiene fondos suficientes.',
//   '3004': 'La tarjeta ha sido identificada como una tarjeta robada.',
//   '3005': 'La tarjeta ha sido rechazada por el sistema antifraude. Rechazada por coincidir con registros en lista negra.',
//   '3006': 'La operación no esta permitida para este cliente o esta transacción.',
//   '3009': 'La tarjeta fue reportada como perdida.',
//   '3010': 'El banco ha restringido la tarjeta.',
//   '3011': 'El banco ha solicitado que la tarjeta sea retenida. Contacte al banco.',
//   '3012': 'Se requiere solicitar al banco autorización para realizar este pago.',
//   '3201': 'Comercio no autorizado para procesar pago a meses sin intereses.',
//   '3203': 'Promoción no valida para este tipo de tarjetas.',
//   '3204': 'El monto de la transacción es menor al mínimo permitido para la promoción.',
//   '3205': 'Promoción no permitida.',
// }

const validateEmail = (email) => {
  return email.match(
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  )
}

const Checkout = (props) => {
  const navigate = useNavigate()
  // const params = useParams()

  const data = JSON.parse(localStorage.getItem('data'))

  // const [deviceDataId, setDeviceDataId] = useState('')
  // const initOpenPay = () => {
  //   // if (deviceDataId !== '') return

  //   window.OpenPay.setId(process.env.REACT_APP_OPENPAY_ID)
  //   window.OpenPay.setApiKey(process.env.REACT_APP_OPENPAY_PK)
  //   window.OpenPay.setSandboxMode(true)// https://github.com/open-pay/openpay-js#fraud-detection-using-device-data
  //   var deviceDataId = window.OpenPay.deviceData.setup()
  //   setDeviceDataId(deviceDataId)
  // }

  const { rubrica } = data

  const { auth, firestore, functions, openPay } = useAuth()
  const { sessionId, tokenId } = openPay

  const { credentials, user } = auth

  const [subscriptionChecked, setSubscriptionChecked] = useState(false)
  const onSubscriptionCheckChange = (event) => {
    setSubscriptionChecked(event.target.checked)
  }

  const [name, setName] = useState(credentials !== null ? user.name : '')
  const handleNameChanged = (event) => {
    setName(event.target.value)
  }

  const [emailError, setEmailError] = useState(false)
  const [email, setEmail] = useState(credentials !== null ? credentials.email : '')
  const handleEmailChanged = (event) => {
    const value = event.target.value
    setEmailError(value === '' ? false : !validateEmail(value))
    setEmail(value)
  }

  const [paymentMethod, setPaymentMethod] = useState()
  const onPaymentSelected = (payment) => {
    setPaymentMethod(payment)
  } 

  const [address, setAddress] = useState({})
  const onAddressSelected = (address) => {
    setAddress(address)
  } 

  const [shippingCost, setShippingCost] = useState(0)

  const [couponCode, setCouponCode] = useState('')
  const handleCouponCodeChange = (event) => {
    setCouponCode(event.target.value)
  }

  const [applying, setApplying] = useState(false)
  const [coupon, setCoupon] = useState()
  const [couponLabel, setCouponLabel] = useState('')

  const applyCoupon = async () => {
    setApplying(true)
    const data = await firestore.getCoupon(email, couponCode)

    if (data.response === 'valid') {
      setCoupon(data.coupon)
      setCouponLabel(data.coupon.label)
    } else {
      setCoupon(undefined)
      setCouponLabel(data.label)
    }

    setCouponCode('')
    setApplying(false)
  }

  const [quantity, setQuantity] = useState(rubrica['bolsas'])
  const handleQuantityChange = (event) => {
    if (event.target.value > 5) {
      setQuantity(5)
    } else if (event.target.value <= 0) {
      setQuantity(1)
    } else {
      setQuantity(event.target.value)
    }
  }

  const [price] = useState((rubrica['taza'] * rubrica['costo'] / 340).toFixed(2))
  const [days, setDays] = useState(Math.floor(rubrica['bolsas'] * 340 / (rubrica['taza'] * rubrica['cantidad'])))

  useEffect(() => {
    setDays(Math.floor(quantity * 340 / (rubrica['taza'] * rubrica['cantidad'])))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [quantity])

  const [shippingData, setShippingData] = useState()
  const calculateShipping = async () => {
    if (address['code'] === undefined) return

    let data = {}
    if (shippingData === undefined) {
      data = await functions.getShipping(address['code'])
      setShippingData(data)
    } else {
      data = shippingData
    }
    console.log(data)

    const zone = data['zona_extendida']
    const coverage = data['cobertura']

    const local = coverage.filter((item) => item.includes('local'))
    const type = local.length > 0 ? 'local' : 'nacional'

    const weight = quantity * 340

    // Kg    Local   Nacional
    // 1     63      88
    // 5     81      103
    // extra 7       14

    const rate = {
      'local': {
        '1': 75,
        '5': 95,
        'extra': 7
      },
      'nacional': {
        '1': 105,
        '5': 140,
        'extra': 14
      }
    }

    const extraWeight = weight - 5000
    const extraCost = Math.ceil((extraWeight < 0 ? 0 : extraWeight) / 1000) * rate[type]['extra']

    const zoneCost = zone === 'NO' ? 0 : 170

    const costPerKg = weight < 1000 ? rate[type]['1'] : rate[type][5]

    const cost = costPerKg + extraCost + zoneCost
    setShippingCost(cost)
  }

  const getDiscount = () => {
    var discount = 0
    if (coupon === undefined) return discount

    const type = coupon['type']

    if (type === 'bag') {
      discount = rubrica['costo'] * coupon['discount']
    } else {
      discount = (quantity * rubrica['costo']) * coupon['discount']
    }

    return discount
  }

  // const [total, setTotal] = useState(0)
  const getTotal = () => {
    const discount = getDiscount()
    // setTotal(total)
    return (quantity * rubrica['costo']) + (shippingCost * 1.16) - discount
  }

  const [order, setOrder] = useState()

  const [paying, setPaying] = useState(false)
  const [paymentError, setPaymentError] = useState('')

  const requestShipment = async () => {
    const address = order['shipping']['address']
    const customer = order['customer']

    const data = await functions.requestShipment({
      'id': order['id'],
      'address': address,
      'customer': {
        'email': customer['email'],
        'name': customer['name']
      },
      'package': {
        'height': 17,
        'length': 7,
        'width': 12 * quantity,
        'weight': 0.34 * quantity
      },
      'cost': order['amount']
    })

    await firestore.updateOrder(order['id'], {
      'shipment': data,
    })
  }

  const callCreateCharge = async () => {
    const charge = {
      'isSubscription': subscriptionChecked,
      'config': {
        'deviceSessionId': sessionId,
        'sourceId': tokenId,
      },
      'customer': order['customer'],
      'order': {
        'amount': order['amount'],
        'description': `${quantity} bolsas de grano ${rubrica['grano']}`,
        'id': order['id'],
      },
    }

    if (subscriptionChecked) {
      charge['uid'] = credentials.uid
      // data = await functions.createSubscription(charge)
    }

    const data = await functions.createCharge(charge)
    console.log(data)

    if (data === null || data['error_code'] !== undefined) {
      setPaymentError('Tarjeta declinada')
      // await firestore.updateOrder(order['id'], {
      //   'status': 'declined',
      // })
    } else {
      // await firestore.updateOrder(order['id'], {
      //   'status': data['status'],
      // })

      if (coupon !== undefined) {
        await firestore.useCouponCode(email, coupon.id)
      }

      requestShipment()

      const url = window.location.href

      if (url.includes('dashboard')) {
        navigate(`/dashboard/order/${order['id']}`)
      } else {
        navigate(`/order/${order['id']}`)
      }
    }

    setPaying(false)
  }

  const onPay = async () => {
    setPaymentError('')
    setPaying(true)

    if (order === undefined) {
      data['rubrica']['bolsas'] = quantity
      const newOrder = {
        'amount': getTotal(),
        'customer': {
          'email': email,
          'name': credentials !== null && credentials !== undefined ? auth.user.name : name
        },
        'payment': {
          'type': paymentMethod['type'],
          'number': paymentMethod['card']['number'].slice(-4),
        },
        'data': data,
        'shipping': {
          'address': address,
          'cost': shippingCost,
        },
      }

      if (coupon !== undefined) {
        newOrder['payment']['coupon'] = coupon
      }

      const orderId = await firestore.createOrder(newOrder)
      newOrder['id'] = orderId
      setOrder(newOrder)
    }

    if (tokenId === '') {
      const creditCard = paymentMethod['card']
      const token = {
        'card_number': creditCard['number'].replace(/\s/g, ''),
        'holder_name': creditCard['name'],
        'expiration_year': creditCard['expire'].split('/')[1],
        'expiration_month': creditCard['expire'].split('/')[0],
        'cvv2': creditCard['cvv'],
        // 'address': {
        //   'city': address['city'],
        //   'country_code': 'MX',
        //   'line1': `${address['street']} ${address['outside']}, ${address['neighborhood']}`,
        //   'line2': `${address['inside'] !== undefined ? ` Int. ${address['inside']}` : ''} ${address['others'] !== undefined ? address['others'] : ''}`,
        //   'postal_code': address['code'],
        //   'state': address['state'],
        // }
      }
  
      openPay.createToken(token)
    } else {
      openPay.generateSessionId()
    }
  }

  useEffect(() => {
    if (sessionId === '') return
    callCreateCharge()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sessionId])

  useEffect(() => {
    if (tokenId === '') return
    openPay.generateSessionId()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tokenId])

  useEffect(() => {
    setShippingData(undefined)
    if (address !== undefined) {
      calculateShipping()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [address])

  useEffect(() => {
    if (address !== undefined) {
      calculateShipping()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [quantity])

  const PersonalInfo = (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography variant='h6'>
          <b>Información personal</b>
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <TextField
          InputLabelProps={{ shrink: true }}
          label='Nombre'
          value={name}
          onChange={handleNameChanged}
          placeholder='Ingresa tu nombre completo'
          fullWidth
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          error={emailError}
          helperText={ emailError ? 'Ingresa un email válido' : '' }
          InputLabelProps={{ shrink: true }}
          label='Email'
          value={email}
          onChange={handleEmailChanged}
          placeholder='Ingresa tu email'
          fullWidth
        />
      </Grid>
    </Grid>
  )

  const TotalInfo = (
    <Grid container spacing={2}>
      <Grid item xs={12}><Divider /></Grid>
      <Grid item xs={6}>
        <Typography variant='body1' style={{ color: '#777' }}>Subtotal: </Typography>
      </Grid>
      <Grid item xs={6} align='right'>
        <Typography variant='body1' style={{ color: '#777' }}>${quantity * rubrica['costo']}</Typography>
      </Grid>
      <Grid item xs={6}>
        <Typography variant='body1' style={{ color: '#aaa' }}>Descuento: </Typography>
      </Grid>
      <Grid item xs={6} align='right'>
        <Typography variant='body1' sx={{ color: '#aaa' }}>{ getDiscount() === 0 ? '-' : `- $${getDiscount()}` }</Typography>
      </Grid>
      <Grid item xs={6}>
        <Typography variant='body1' style={{ color: '#999' }}>Envío + IVA: </Typography>
      </Grid>
      <Grid item xs={6} align='right'>
        <Typography variant='body1' sx={{ color: '#aaa' }}>{ shippingCost === 0 ? '-' : `+ $${(shippingCost * 1.16).toFixed(2)}` }</Typography>
      </Grid>
      <Grid item xs={12}><Divider /></Grid>
      <Grid item xs={6}>
        <Typography variant='h5'><b>Total: </b></Typography>
      </Grid>
      <Grid item xs={6} align='right'>
        <Typography variant='h5'><b>${getTotal().toFixed(2)}</b></Typography>
      </Grid>
    </Grid>
  )

  const SummaryInfo = (
    <Grid container spacing={4}>
      <Grid item xs={12}>
        <Typography variant='h5' gutterBottom>
          <b>Tu compra</b>
        </Typography>
        <Typography variant='body1'>
          De acuerdo con tu receta preferida estimamos que el número de bolsas que estás poniendo en tu carrito de compra te van a rendir para <b>{days}</b> días y el precio de tu orden equivale a <b>${price}</b> por taza de café Rúbrica.
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Grid container spacing={2} alignItems='center'>
          <Grid item xs={12} md={4}>
            <TextField
              label='No. de bolsas'
              type='number'
              fullWidth
              // inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
              InputProps={{
                inputProps: { 
                  min: 1, 
                  max: 5 
                },
              }}
              InputLabelProps={{
                shrink: true,
              }}
              value={quantity}
              onChange={handleQuantityChange}
            />
          </Grid>
          <Grid item xs={12} md={8}>
            <Typography variant='body1'>
              Precio: <b>${rubrica.costo}</b> por unidad
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )

  return (
    <Box sx={{ p: { xs: 2, md: 8 } }}>
      <Grid container spacing={8}>
        <Grid item xs={12} md={6} lg={7} order={{ xs: 2, md: 1 }}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography variant='h5'>
                <b>Detalles del pago</b>
              </Typography>
              <Typography variant='body1'>
                Completa tu compra ingresando los siguientes datos.
              </Typography>
            </Grid>
            <Grid item xs={12}>{PersonalInfo}</Grid>
            <Grid item xs={12}>
              <Payment onPaymentSelected={onPaymentSelected} />
            </Grid>
            <Grid item xs={12}>
              <Address onAddressSelected={onAddressSelected} />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} md={6} lg={5} order={{ xs: 1, md: 2 }}>
          <Grid container spacing={2}>
            <Grid item xs={12}>{SummaryInfo}</Grid>
            <Grid item xs={12}>{TotalInfo}</Grid>
            <Grid item xs={12}>
              <Grid container spacing={2} alignItems='center'>
                <Grid item xs={12}>
                  <Typography variant='body1'></Typography>
                </Grid>
                <Grid item xs={12} md={8}>
                  <TextField
                    label='Cúpon de descuento'
                    fullWidth
                    InputLabelProps={{
                      shrink: true,
                    }}
                    placeholder='Ingresa un código promocional'
                    value={couponCode}
                    onChange={handleCouponCodeChange}
                  />
                </Grid>
                <Grid item xs={12} md={4}>
                  <Button disabled={applying || couponCode === ''} fullWidth variant='contained' disableElevation size='large' onClick={() => applyCoupon()} style={{ height: '100%' }}>
                    Aplicar
                  </Button>
                </Grid>
                <Grid item xs={12} md={12} style={{ display: couponLabel === '' ? 'none' : 'inherit' }}>
                  <Typography variant='body2'>
                    <b><i>{couponLabel}</i></b>
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            {
              credentials !== null ? (
                <Grid item xs={12}>
                  <FormControlLabel control={<Checkbox value={subscriptionChecked} onChange={onSubscriptionCheckChange}/>} label={<Typography variant='body2'>Acepto la suscripción para recibir mi café mensualmente con cargo automático</Typography>} />
                </Grid>
              ) : null
            }
            {
              paymentError !== '' ? (
                <Grid item xs={12}>
                  <Alert severity='error'>{paymentError}</Alert>
                </Grid>
              ) : null
            }
            <Grid item xs={12}>
              <Button disabled={paying || name === '' || !validateEmail(email) || shippingCost === 0 || paymentMethod === undefined} disableElevation fullWidth variant='contained' size='large' onClick={onPay}>
                <b>Proceder al pago</b>
              </Button>
            </Grid>
            <Grid item xs={12} align='center'>
              <Box
                component='img' 
                src='/assets/images/LogotipoOpenpay-01.jpg'
                sx={{ width: '40%' }}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={paying}
      >
        <CircularProgress color='inherit' />
      </Backdrop>
    </Box>
  )
}

export default Checkout