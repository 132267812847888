import React, { useState } from 'react'

import {
  FormControl,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
} from '@mui/material'

const Perfume = (props) => {
  const { current, getCurrentValue } = props

  const values = {
    'perfume_1': 4.5,
    'perfume_2': 4,
    'perfume_3': 3,
    'perfume_4': 5,
    'perfume_5': 2,
    'perfume_6': 1.5,
    'perfume_7': 1,
  }
  
  const [value, setValue] = useState(current !== undefined ? current.id : '')

  const handleRadioChange = (event) => {
    setValue(event.target.value)
    getCurrentValue({
      'id': event.target.value,
      'value': values[event.target.value],
    })
  }

  return (
    <Grid container alignContent='center' alignItems='center' direction='column' justifyContent='center'>
      <Grid item xs={12}>
        <FormControl>
          <RadioGroup value={value} onChange={handleRadioChange}>
            <FormControlLabel value='perfume_1' control={<Radio />} label='Maderas' />
            <FormControlLabel value='perfume_2' control={<Radio />} label='Especias' />
            <FormControlLabel value='perfume_3' control={<Radio />} label='Almizcle' />
            <FormControlLabel value='perfume_4' control={<Radio />} label='Dulce' />
            <FormControlLabel value='perfume_5' control={<Radio />} label='Cítrico' />
            <FormControlLabel value='perfume_6' control={<Radio />} label='Fresco' />
            <FormControlLabel value='perfume_7' control={<Radio />} label='Floral' />
          </RadioGroup>
        </FormControl>
      </Grid>
    </Grid>
    
  )
}

export default Perfume