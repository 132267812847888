import React, { useState } from 'react'

import {
  FormControl,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
} from '@mui/material'

const Brand = (props) => {
  const { current, getCurrentValue, machine } = props

  const values = {
    'brand_1': {
      'label': 'De\'Longhi',
      'value': 3
    }, 
    'brand_2': {
      'label': 'Krups',
      'value': 2.5
    }, 
    'brand_3': {
      'label': 'Saeco',
      'value': 2
    }, 
    'brand_4': {
      'label': 'Jura',
      'value': 1.5
    },
    'brand_5': {
      'label': 'Oster',
      'value': 4
    },
    'brand_6': {
      'label': 'Smeg',
      'value':3.5
    },
    'brand_7': {
      'label': 'Capresso',
      'value': 2
    },
    'brand_8': {
      'label': 'Marzocco',
      'value': 1.5
    }, 
    'brand_9': {
      'label': 'Black and Decker',
      'value': 3.5
    }, 
    'brand_10': {
      'label': 'Hamilton Beach',
      'value': 3
    }, 
    'brand_11': {
      'label': 'Cuisinart',
      'value': 2
    },
    'brand_12': {
      'label': 'Braun',
      'value': 1.5
    },
    'brand_13': {
      'label': 'Bodum',
      'value': 4
    },
    'brand_14': {
      'label': 'Hario',
      'value': 3.5
    },
    'brand_15': {
      'label': 'Kalita',
      'value': 3
    }, 
    'brand_16': {
      'label': 'Melitta',
      'value': 2.5
    }, 
    'brand_17': {
      'label': 'Chemex',
      'value': 2
    }, 
    'brand_18': {
      'label': 'Fellow',
      'value': 1.5
    },
    'brand_19': {
      'label': 'Bialetti',
      'value': 2.5
    },
    'brand_20': {
      'label': 'Stelton',
      'value': 2
    },
    'brand_21': {
      'label': 'Alessi',
      'value': 1.5
    },
    'brand_22': {
      'label': 'Otra',
      'value': 1
    },
  }

  const items = {
    'coffee_1': ['brand_1', 'brand_2', 'brand_3', 'brand_4', 'brand_22'],
    'coffee_2': ['brand_1', 'brand_2', 'brand_5', 'brand_6', 'brand_7', 'brand_8', 'brand_22'],
    'coffee_3': ['brand_2', 'brand_5', 'brand_9', 'brand_10', 'brand_11', 'brand_12', 'brand_22'],
    'coffee_4': ['brand_13', 'brand_14', 'brand_15', 'brand_16', 'brand_17', 'brand_18', 'brand_22'],
    'coffee_5': ['brand_13', 'brand_14', 'brand_15', 'brand_16', 'brand_22'],
    'coffee_6': ['brand_19', 'brand_20', 'brand_21', 'brand_22'],
  }

  const [value, setValue] = useState(current !== undefined ? current.id : '')
  //   current === undefined ? '' : Object.keys(values).find(key => values[key] === current)
  // )

  const handleRadioChange = (event) => {
    setValue(event.target.value)
    getCurrentValue({
      'id': event.target.value,
      'value': values[event.target.value].value,
    })
  }

  return (
    <Grid container alignContent='center' alignItems='center' direction='column' justifyContent='center'>
      <Grid item xs={12}>
        <FormControl>
          <RadioGroup value={value} onChange={handleRadioChange}>
            {
              items[machine].map((item) => <FormControlLabel key={`${machine}_${item}`} value={item} control={<Radio />} label={values[item].label}/>)
            }
          </RadioGroup>
        </FormControl>
      </Grid>
    </Grid>
  )
}

export default Brand