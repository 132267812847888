import React, { useState } from 'react'

import {
  FormControl,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
} from '@mui/material'

const Dessert = (props) => {
  const { current, getCurrentValue } = props

  const values = {
    'dessert_1': 4.5,
    'dessert_2': 4,
    'dessert_3': 3,
    'dessert_4': 5,
    'dessert_5': 2,
    'dessert_6': 1.5,
    'dessert_7': 1,
  }
  
  const [value, setValue] = useState(current !== undefined ? current.id : '')

  const handleRadioChange = (event) => {
    setValue(event.target.value)
    getCurrentValue({
      'id': event.target.value,
      'value': values[event.target.value],
    })
  }

  return (
    <Grid container alignContent='center' alignItems='center' direction='column' justifyContent='center'>
      <Grid item xs={12}>
        <FormControl>
          <RadioGroup value={value} onChange={handleRadioChange}>
            <FormControlLabel value='dessert_1' control={<Radio />} label='Brownie' />
            <FormControlLabel value='dessert_2' control={<Radio />} label='Roles de canela' />
            <FormControlLabel value='dessert_3' control={<Radio />} label='Pay de nuez' />
            <FormControlLabel value='dessert_4' control={<Radio />} label='Key Lime Pie' />
            <FormControlLabel value='dessert_5' control={<Radio />} label='Pavlova con frutos rojos' />
            <FormControlLabel value='dessert_6' control={<Radio />} label='Strudel de manzana' />
            <FormControlLabel value='dessert_7' control={<Radio />} label='Mousse de Maracuyá' />
          </RadioGroup>
        </FormControl>
      </Grid>
    </Grid>
    
  )
}

export default Dessert