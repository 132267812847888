import { useState } from 'react'

import app from 'config/firebase'

import {
  addDoc,
  collection,
  deleteDoc,
  doc,
  getDoc,
  getDocs,
  getFirestore,
  onSnapshot,
  query,
  serverTimestamp,
  setDoc,
//   Timestamp,
  updateDoc,
  where
} from 'firebase/firestore'

const db = getFirestore(app)

export const useProvideFirestore = () => {
  const [user, setUser] = useState()
  const [coupons, setCoupons] = useState()
  const [orders, setOrders] = useState()
  const [myRubrica, setMyRubrica] = useState()
  
  const createProfile = async (uid, user) => {
    const docRef = doc(db, 'users', uid)
    const docSnap = await getDoc(docRef)
    
    if (!docSnap.exists()) {
      user['createdAt'] = serverTimestamp()
      user['updatedAt'] = serverTimestamp()
      user['role'] = 'user'
      user['uid'] = uid
      await setDoc(docRef, user)
    }
  }

  const createOrder = async (order) => {
    const ordersRef = collection(db, 'orders')
    
    order['createdAt'] = serverTimestamp()
    order['updatedAt'] = serverTimestamp()
    order['status'] = 'created'
    order['printed'] = false

    const doc = await addDoc(ordersRef, order)

    return doc.id
  }

  const getOrder = async (id) => {
    const ordersRef = doc(db, 'orders', id)
    const docSnap = await getDoc(ordersRef)
    var order = docSnap.data()
    order['id'] = docSnap.id
    return order
  }

  const updateOrder = async (id, data) => {
    const ordersRef = doc(db, 'orders', id)
    data['updatedAt'] = serverTimestamp()
    await updateDoc(ordersRef, data)
  }

  const saveRubrica = async (uid, rubrica) => {
    const rubricasRef = collection(db, 'rubricas')
    const q = query(rubricasRef, where('id', '==', uid))
    
    const querySnapshot = await getDocs(q)

    const data = {
      'id': uid,
      'data': rubrica
    }

    if (querySnapshot.empty) {
      data['createdAt'] = serverTimestamp()
      data['updatedAt'] = serverTimestamp()
      await addDoc(rubricasRef, data)
    } else {
      const doc = querySnapshot.docs[0]
      data['updatedAt'] = serverTimestamp()
      await updateDoc(doc.ref, data)
    }
  }

  const getMyRubrica = async (uid) => {
    const rubricasRef = collection(db, 'rubricas')

    const q = query(rubricasRef, where('id', '==', uid))

    onSnapshot(q, (querySnapshot) => {
      if (!querySnapshot.empty) {
        setMyRubrica(querySnapshot.docs[0].data()['data'])
      }
    })
  }

  const getCouponesByEmail = async (email) => {
    const codesRef = collection(db, 'codes')
    const q = query(codesRef, where('email', '==', email))
    
    const querySnapshot = await getDocs(q)

    return querySnapshot.docs
  }

  const getCoupon = async (email, id) => {
    const docs = await getCouponesByEmail(email)
    docs.filter((doc) => doc.data()['id'] === id)

    if (docs.length > 0) {
      return {
        'coupon': {},
        'label': 'Lo sentimos, ya utilizaste este cupón',
        'response': 'used',
      }
    }

    const couponRef = collection(db, 'coupons')
    const q = query(couponRef, where('id', '==', id))
    const querySnapshot = await getDocs(q)

    if (!querySnapshot.empty) {
      return {
        'coupon': querySnapshot.docs[0].data(),
        'label': '',
        'response': 'valid',
      }
    }

    return {
      'coupon': {},
      'label': 'Cupón no válido',
      'response': 'invalid',
    }
  }

  const useCouponCode = async (email, id) => {
    const docs = await getCouponesByEmail(email, id)

    const data = {
      'id': id,
      'email': email,
      'createdAt': serverTimestamp(),
      'updatedAt': serverTimestamp()
    }
    
    const codesRef = collection(db, 'codes')

    if (docs.length === 0) {
      await addDoc(codesRef, data)
      return false
    } else {
      var used = false
      docs.forEach((doc) => {
        if (doc.data()['email'] === email) {
          used = true
        }
      })
      return used
    }
  }

  const getOrders = async () => {
    const q = collection(db, 'orders')
    onSnapshot(q, (snap) => {
      const results = []
      snap.forEach((doc) => {
        const order = doc.data()
        const status = order['status']
        if (order['subscriptionId'] === undefined && (status === 'declined' || status === 'created')) return

        results.push({
          ...doc.data(),
          id: doc.id
        })
      })
      setOrders(results)
    })
  }

  const getProfile = async (uid) => {
    const docRef = doc(db, 'users', uid)
    onSnapshot(docRef, doc => {
      setUser(doc.data())
    })
  }

  const createCoupon = async (coupon) => {
    const ordersRef = collection(db, 'coupons')
    
    coupon['createdAt'] = serverTimestamp()
    coupon['updatedAt'] = serverTimestamp()
    coupon['type'] = 'total'

    const doc = await addDoc(ordersRef, coupon)

    return doc.id
  }

  const getCoupons = () => {
    const couponsRef = collection(db, 'coupons')
    onSnapshot(couponsRef, (querySnapshot) => {
      const coupons = []
      querySnapshot.forEach((doc) => {
        const coupon = doc.data()
        coupon['couponId'] = doc.id
        coupons.push(coupon)
      })
      setCoupons(coupons)
    })
  }

  const deleteCoupon = async (id) => {
    await deleteDoc(doc(db, 'coupons', id))
  }

  return {
    coupons,
    createCoupon,
    createOrder,
    createProfile,
    deleteCoupon,
    getCoupon,
    getMyRubrica,
    getCoupons,
    getOrder,
    getOrders,
    getProfile,
    myRubrica,
    orders,
    saveRubrica,
    updateOrder,
    useCouponCode,
    user
  }
}