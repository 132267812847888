export const useCalculations = () => {
  const aromas = {
    'aroma_1': 'Cítricos',
    'aroma_2': 'Frutos rojos',
    'aroma_3': 'Frutos amarillos',
    'aroma_4': 'Floral',
    'aroma_5': 'Chocolate',
    'aroma_6': 'Especias',
    'aroma_7': 'Nueces',
  }
  
  const process = {
    'aroma_1': 'Lavado',
    'aroma_2': 'Natural',
    'aroma_3': 'Honey',
    'aroma_4': 'Lavado',
    'aroma_5': 'Lavado',
    'aroma_6': 'Lavado',
    'aroma_7': 'Lavado',
  }
  
  const flavors = {
    'flavor_1': 'Lima',
    'flavor_2': 'Mandarina',
    'flavor_3': 'Toronja',
    'flavor_4': 'Naranja',
  
    'flavor_5': 'Cereza negra',
    'flavor_6': 'Zarzamora',
    'flavor_7': 'Frambuesa',
    'flavor_8': 'Fresa',
    
    'flavor_9': 'Guayaba',
    'flavor_10': 'Ciruela',
    'flavor_11': 'Maracuyá',
    'flavor_12': 'Mango',
  
    'flavor_13': 'Jazmin',
    'flavor_14': 'Azahar',
    'flavor_15': 'Lemongrass',
    'flavor_16': 'Jengibre',
  
    'flavor_17': 'Cacao',
    'flavor_18': 'Madera',
    'flavor_19': 'Te negro',
    'flavor_20': 'Tabaco',
  
    'flavor_21': 'Canela',
    'flavor_22': 'Pimienta',
    'flavor_23': 'Cardamomo',
    'flavor_24': 'Clavo',
  
    'flavor_25': 'Almendra tostada',
    'flavor_26': 'Malta',
    'flavor_27': 'Caramelo',
    'flavor_28': 'Nuez',
  }
  
  const grains = {
    'grain_1': 'molido',
    'grain_2': 'entero',
  }
  
  const points = {
    'aroma_1': ['aroma_1', 'aroma_1'],
    'aroma_2': ['aroma_2', 'aroma_2'],
    'aroma_3': ['aroma_3', 'aroma_3'],
    'aroma_4': ['aroma_4', 'aroma_4'],
    'aroma_5': ['aroma_5', 'aroma_5'],
    'aroma_6': ['aroma_6', 'aroma_6'],
    'aroma_7': ['aroma_7', 'aroma_7'],
    'chocolate_1': ['aroma_6'],
    'chocolate_2': ['aroma_5'],
    'chocolate_3': ['aroma_2'],
    'chocolate_4': ['aroma_7'],
    'chocolate_5': ['aroma_3'],
    'chocolate_6': ['aroma_1'],
    'dessert_1': ['aroma_5'],
    'dessert_2': ['aroma_6'],
    'dessert_3': ['aroma_7'],
    'dessert_4': ['aroma_1'],
    'dessert_5': ['aroma_2'],
    'dessert_6': ['aroma_3'],
    'drink_1': ['aroma_5'],
    'drink_2': ['aroma_6'],
    'drink_3': ['aroma_7'],
    'drink_4': ['aroma_1'],
    'drink_5': ['aroma_2'],
    'drink_6': ['aroma_3'],
    'drink_7': ['aroma_4'],
    'milk_1': ['aroma_5', 'aroma_6', 'aroma_7'],
    'milk_2': ['aroma_1', 'aroma_2', 'aroma_3', 'aroma_4'],
    'moment_1': ['aroma_2', 'aroma_7'],
    'moment_2': ['aroma_5', 'aroma_6'],
    'moment_3': ['aroma_4'],
    'moment_4': ['aroma_5'],
    'moment_6': ['aroma_1', 'aroma_3', 'aroma_4'],
    'perfume_1': ['aroma_5'],
    'perfume_2': ['aroma_6'],
    'perfume_3': ['aroma_7'],
    'perfume_4': ['aroma_3'],
    'perfume_5': ['aroma_1'],
    'perfume_6': ['aroma_2'],
    'perfume_7': ['aroma_4'],
    'recipe_1': ['aroma_6'],
    'recipe_2': ['aroma_6'],
    'recipe_3': ['aroma_7'],
    'recipe_4': ['aroma_3'],
    'recipe_5': ['aroma_5'],
    'recipe_6': ['aroma_7'],
    'recipe_7': ['aroma_1', 'aroma_2'],
    'recipe_8': ['aroma_7'],
    'recipe_9': ['aroma_5'],
    'recipe_10': ['aroma_6'],
    'recipe_11': ['aroma_1', 'aroma_4'],
    'recipe_12': ['aroma_4'],
    'recipe_13': ['aroma_2'],
    'sugar_1': ['aroma_1', 'aroma_2', 'aroma_3', 'aroma_4'],
    'sugar_2': ['aroma_5', 'aroma_6', 'aroma_7'],
    'tea_1': ['aroma_5'],
    'tea_2': ['aroma_6'],
    'tea_3': ['aroma_7'],
    'tea_4': ['aroma_1'],
    'tea_5': ['aroma_2'],
    'tea_6': ['aroma_3'],
    'tea_7': ['aroma_4'],
    'wine_1': ['aroma_5'],
    'wine_2': ['aroma_6'],
    'wine_3': ['aroma_7'],
    'wine_4': ['aroma_1'],
    'wine_5': ['aroma_2'],
    'wine_6': ['aroma_3'],
  }
  
  
  const quantity = {
    'quantity_1': 1,
    'quantity_2': 2,
    'quantity_3': 3,
    'quantity_4': 5,
  }
  
  const cups = {
    'cup_1': 7,
    'cup_2': 12,
    'cup_3': 14,
    'cup_4': 16,
    'cup_5': 25,
  }
  
  const calculateAroma = (blue, orange, red, yellow, recipes) => {
    const blueKeys = Object.keys(blue).map((key) => blue[key].id)
    const orangeKeys = Object.keys(orange).map((key) => orange[key].id)
    const redKeys = Object.keys(red).map((key) => red[key].id)
    const yellowKeys = Object.keys(yellow).filter((key) => key !== undefined).map((key) => yellow[key].id)
  
    const keys = [...blueKeys, ...orangeKeys, ...redKeys, ...yellowKeys, ...recipes]
    
    const total = []
    keys.forEach((key) => {
      if (key === undefined) return
      if (points[key] === undefined) return
  
      total.push(...points[key])
    })
  
    const result = {}
    total.forEach((key) => {
      if (result[key]) {
        result[key] += 1;
      } else {
        result[key] = 1;
      }
    })
  
    let sorted = Object.entries(result).sort((prev, next) => prev[1] - next[1])
    let value = sorted.pop()
  
    if (value === 'undefined') return ''
  
    return value !== undefined ? value[0] : ''
  }
  
  const calculateRubrica = (blue, orange, red, yellow, recipes, grain) => {
    const aroma = calculateAroma(blue, orange, red, yellow, recipes)
  
    const cupId = yellow[7].id
    const quantityId = orange[16].id
  
    const bags = cups[cupId] * quantity[quantityId] * 30 / 340
    const rounded = Math.ceil(bags)
  
    const rubrica = {
      'varietal': 'Pluma Hidalgo',
      'proceso': process[aroma] !== undefined ? process[aroma] : '-',
      'aromatico': aromas[aroma] !== undefined ? aromas[aroma] : '-',
      'notas': red[15] !== undefined ? flavors[red[15].id] : '-',
      'origen': 'Oaxaca',
      'grano': grains[grain] !== undefined ? grains[grain] : '-',
      'bolsas': rounded > 5 ? 5 : rounded,
    }
  
    rubrica['costo'] = rubrica['grano'] === 'entero' ? 295 : 305
  
    // rubrica['taza'] = cups[cupId] *  rubrica['costo'] / 340
    // rubrica['dias'] = Math.floor(rubrica['bolsas'] * 340 / (cups[cupId] * quantity[quantityId]))
  
    rubrica['taza'] = cups[cupId]
    rubrica['cantidad'] = quantity[quantityId]
  
    return rubrica
  }

  return {
    calculateAroma,
    calculateRubrica
  }
}
