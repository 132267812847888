import * as React from 'react'

import { 
  BrowserRouter,
  Route,
  Routes
} from 'react-router-dom'

import ProtectedRoute from 'routes/protected'

// import Register from 'pages/register'
// import SignIn from 'pages/signin'
// import SignUp from 'pages/signup'
// import Verify from 'pages/verify'

import Landing from 'pages/landing'
import Dashboard from 'pages/dashboard'
import Admin from 'pages/admin'

const App = (props) => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path='/*' element={<Landing />} />
        {/* <Route path='/*' element={<Landing />} /> */}
        {/* <Route path='etiqueta' element={<Label data={data} />} /> */}
        <Route path='admin/*' 
          element={
            <ProtectedRoute role='admin'>
              <Admin />
            </ProtectedRoute>
          } 
        />
        <Route path='dashboard/*' 
          element={
            <ProtectedRoute role='user'>
              <Dashboard />
            </ProtectedRoute>
          } 
        />
        {/* <Route path='register/*' 
          element={
            <ProtectedRoute role='none'>
              <Register />
            </ProtectedRoute>
          } 
        /> */}
        {/* <Route path='forgot' element={<Outlet />} /> */}
        {/* <Route path='signin' element={<SignIn />} /> */}
        {/* <Route path='signup' element={<SignUp />} /> */}
        {/* <Route path='verify' element={<Verify />} /> */}
      </Routes>
    </BrowserRouter>
  )
}

export default App