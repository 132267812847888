import React, { useEffect, useState } from 'react'

import useAuth from 'hooks/auth'

import {
  Backdrop,
  Button,
  CircularProgress,
  Grid,
  IconButton,
  TextField,
  Typography,
} from '@mui/material'

import DeleteIcon from '@mui/icons-material/Delete'

import { DataGrid, esES } from '@mui/x-data-grid'

const Coupons = () => {

  const { firestore } = useAuth()
  const { coupons, createCoupon, deleteCoupon, getCoupons } = firestore

  const [rows, setRows] = useState([])

  const [open, setOpen] = useState(false)

  const [code, setCode] = useState('')
  const handleCodeChange = (event) => {
    setCode(event.target.value)
  }

  const [label, setLabel] = useState('')
  const handleLabelChange = (event) => {
    setLabel(event.target.value)
  }

  const [discount, setDiscount] = useState(0)
  const handleDiscountChange = (event) => {
    const value = event.target.value
    setDiscount(value > 100 ? 100 : value)
  }

  const onCreateCoupon = async () => {
    setOpen(true)
    await createCoupon({
      'id': code,
      'label': label,
      'discount': discount / 100
    })

    setCode('')
    setLabel('')
    setDiscount(0)
    setOpen(false)
  }

  const onDeleteCoupon = async (id) => {
    setOpen(true)
    await deleteCoupon(id)
    setOpen(false)
  }

  const columns = [
    { field: 'id', headerName: 'Código', minWidth: 156, flex: 1 },
    { field: 'label', headerName: 'Descripción', minWidth: 120, flex: 1 },
    { 
      field: 'discount', 
      headerName: 'Descuento (%)', 
      minWidth: 120, 
      flex: 1,
      renderCell: (params) => {
        return `${params.value * 100}%`
      }
    },
    { 
      field: 'couponId', 
      headerName: '', 
      width: 96, 
      renderCell: (params) => {
        return <IconButton onClick={() => onDeleteCoupon(params.value)}><DeleteIcon /></IconButton>
      }
    },
  ]

  useEffect(() => {
    getCoupons()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (coupons === undefined) return
    setRows(coupons)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [coupons])

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={4}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography variant='h5'>
              Crear cupón
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <TextField
              InputLabelProps={{ shrink: true }}
              id='outlined-code-input'
              label='Código'
              value={code}
              onChange={handleCodeChange}
              placeholder='Ingresa el código'
              fullWidth
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              InputLabelProps={{ shrink: true }}
              id='outlined-label-input'
              label='Descripción'
              value={label}
              onChange={handleLabelChange}
              placeholder='Ingresa la descripción del cupón'
              fullWidth
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              InputLabelProps={{ shrink: true }}
              id='outlined-discount-input'
              type='number'
              InputProps={{
                inputProps: { 
                  min: 0,
                  max: 100
                },
              }}
              label='Porcentaje de Descuento'
              value={discount}
              onChange={handleDiscountChange}
              placeholder='%'
              fullWidth
            />
          </Grid>
          <Grid item xs={12} align='right'>
            <Button disabled={code === '' && label === ''} disableElevation fullWidth variant='contained' onClick={onCreateCoupon}>
              Crear
            </Button>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} md={8}>
        <DataGrid
          autoHeight
          // disableColumnMenu
          columns={columns}
          localeText={esES.components.MuiDataGrid.defaultProps.localeText}
          pageSize={5}
          rows={rows}
          rowsPerPageOptions={[5]}
          sx={{ borderWidth: 0 }}
        />
      </Grid>
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={open}
      >
          <CircularProgress color='inherit' />
      </Backdrop>
    </Grid>
  )
}

export default Coupons