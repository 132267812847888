import React, { useState } from 'react'

import {
  FormControl,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
} from '@mui/material'

const Aroma = (props) => {
  const { current, getCurrentValue } = props

  const values = {
    'aroma_1': 4.5,
    'aroma_2': 4,
    'aroma_3': 2,
    'aroma_4': 1,
    'aroma_5': 5,
    'aroma_6': 3.5,
    'aroma_7': 3,
  }
  
  const [value, setValue] = useState(current !== undefined ? current.id : '')

  const handleRadioChange = (event) => {
    setValue(event.target.value)
    getCurrentValue({
      'id': event.target.value,
      'value': values[event.target.value],
    })
  }

  return (
    <Grid container alignContent='center' alignItems='center' direction='column' justifyContent='center'>
      <Grid item xs={12}>
        <FormControl>
          <RadioGroup value={value} onChange={handleRadioChange}>
            <FormControlLabel value='aroma_1' control={<Radio />} label='Cítricos' />
            <FormControlLabel value='aroma_2' control={<Radio />} label='Frutos rojos' />
            <FormControlLabel value='aroma_3' control={<Radio />} label='Frutos amarillos' />
            <FormControlLabel value='aroma_4' control={<Radio />} label='Floral' />
            <FormControlLabel value='aroma_5' control={<Radio />} label='Chocolate' />
            <FormControlLabel value='aroma_6' control={<Radio />} label='Especias' />
            <FormControlLabel value='aroma_7' control={<Radio />} label='Nueces' />
          </RadioGroup>
        </FormControl>
      </Grid>
    </Grid>
    
  )
}

export default Aroma