import React, { useState } from 'react'

import {
  Link as RouteLink,
  Route,
  Routes
} from 'react-router-dom'

import {
  AppBar,
  Box,
  Button,
  Divider,
  Drawer,
  Fab,
  Grid,
  IconButton,
  Link,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Toolbar,
  Typography,
  useScrollTrigger,
  Zoom,
} from '@mui/material'

import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'
import MenuIcon from '@mui/icons-material/Menu'
import Wizard from 'pages/wizard'

import Checkout from 'pages/checkout'
import Order from 'pages/order'
import SignIn from 'pages/account/signin'
import SignUp from 'pages/account/signup'
import Forgot from 'pages/account/forgot'

const toolbarHeight = 100
const drawerWidth = 240
const navItems = [
  { label: 'INICIO', path: 'https://rubrica.mx' },
  { label: 'NUESTRA PERSONALIDAD', path: 'https://rubrica.mx/personalidad' },
  { label: 'FAQ', path: 'https://rubrica.mx/faq' },
  { label: 'CONTACTO', path: 'https://rubrica.mx/contacto' }
]

const Landing = (props) => {
  const { window } = props
  const [mobileOpen, setMobileOpen] = useState(false)

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen)
  }

  const drawer = (
    <Box onClick={handleDrawerToggle} sx={{ textAlign: 'center' }}>
      <Box component={Link} href='https://rubrica.mx/'>
        <Box
          component='img'
          m={2}
          src='/assets/images/logo.png'
          alt='Rubrica logo'
          sx= {{ height: 48 }}
        />
      </Box>
      <Divider />
      <List>
        {navItems.map((item) => (
          <ListItem component={Link} href={item.path} key={`drawer_${item.label}`} disablePadding style={{ color: '#333' }}>
            <ListItemButton sx={{ textAlign: 'center' }}>
              <ListItemText primary={item.label} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
      <Button component={RouteLink} to='/' key='create' color='rubricaRed' disableElevation sx={{ mt: 2 }} style={{ color: 'white' }} size='large' variant='contained'>
        CREA TU RÚBRICA
      </Button>
    </Box>
  )


  const ScrollTop = (props) => {
    const { children, window } = props

    const trigger = useScrollTrigger({
      target: window ? window() : undefined,
      disableHysteresis: true,
      threshold: 100,
    })

    const handleClick = (event) => {
      const anchor = (event.target.ownerDocument || document).querySelector(
        '#back-to-top-anchor',
      )

      if (anchor) {
        anchor.scrollIntoView({
          behavior: 'smooth',
          block: 'center',
        })
      }
    }

    return (
      <Zoom in={trigger}>
        <Box
          onClick={handleClick}
          role='presentation'
          sx={{ position: 'fixed', bottom: 16, right: 16 }}
        >
          {children}
        </Box>
      </Zoom>
    )
  }

  // const ElevationScroll = ({ children }) => {
  //   const trigger = useScrollTrigger({
  //   disableHysteresis: true,
  //   target: window ? window() : undefined,
  //   threshold: 100
  //   });
  
  //   return React.cloneElement(children, {
  //   elevation: trigger ? 1 : 0,
  //   sx: trigger ? { boxShadow: '0px 1px 1px -1px rgb(0 0 0 / 5%), 0px 1px 1px 0px rgb(0 0 0 / 3%), 0px 2px 1px 0px rgb(0 0 0 / 3%)' } : {},
  //   });
  // }

  const container = window !== undefined ? () => window().document.body : undefined
  
  return (
    <Box sx={{ display: 'grid', gridTemplateRows: 'auto 1fr auto', minHeight: '100vh' }}>
      {/* <ElevationScroll {...props}> */}
        <AppBar elevation={1} style={{ backgroundImage: `url('/assets/images/background.png')`, backgroundRepeat: 'repeat', backgroundSize: 'cover' }} color='inherit' component='nav'>
          <Toolbar sx={{ height: toolbarHeight }}>
            <IconButton
              color='inherit'
              aria-label='open drawer'
              edge='start'
              onClick={handleDrawerToggle}
              sx={{ mr: 2, display: { md: 'none' } }}
            >
              <MenuIcon />
            </IconButton>
            <Box component={Link} href='https://rubrica.mx/'>
              <Box
                component='img'
                m={2}
                src='/assets/images/logo.png'
                alt='Rubrica logo'
                sx= {{ height: 80 }}
              />
            </Box>
            <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'block' } }}>
              <Grid container direction='row' justifyContent='flex-end' alignItems='right'>
                {navItems.map((item) => (
                  <Button component={Link} href={item.path} key={`toolbar_${item.label}`} size='large' sx={{ ml: 2 }} style={{ color: '#333', letterSpacing: '4px' }}>
                    <b>{item.label}</b>
                  </Button>
                ))}
                <Button component={RouteLink} to='/login' key='login' disableElevation sx={{ borderRadius: 8, ml: 4 }} style={{ color: 'white' }} variant='contained'>
                  INICIAR SESIÓN
                </Button>
                <Button component={RouteLink} to='/' key='create' color='rubricaRed' disableElevation sx={{ borderRadius: 8, ml: 2 }} style={{ color: 'white' }} variant='contained'>
                  CREA TU RÚBRICA
                </Button>
              </Grid>
            </Box>
          </Toolbar>
        </AppBar>
      {/* </ElevationScroll> */}
      <Box component='nav'>
        <Drawer
          container={container}
          variant='temporary'
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: 'block', md: 'none' },
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
          }}
        >
          {drawer}
        </Drawer>
      </Box>
      <Box component='main' style={{ width: '100%' }}>
        <Toolbar sx={{ height: toolbarHeight }} />
        <Routes>
          {/* <Route path='' element={<Home />} />
          <Route path='personalidad' element={<Personality />} />
          <Route path='faq' element={<Faq />} />
          <Route path='contacto' element={<Contact />} />
          <Route path='condiciones' element={<Conditions />} />
          <Route path='privacidad' element={<Privacy />} /> */}
          <Route path='' element={<Wizard />} />
          <Route path='login' element={<SignIn />} />
          <Route path='registro' element={<SignUp />} />
          <Route path='olvide' element={<Forgot />} />
          <Route path='checkout' element={<Checkout />} />
          <Route path='order/:id' element={<Order />} />
        </Routes>
      </Box>
      <Box sx={{ bgcolor: '#2a2c2e', pb: 4, px: 8, pt: 8, zIndex: '1' }}>
        <Grid container spacing={8} pb={4}>
          <Grid item xs={12} md={6}>
            <Typography color='white' variant='h5' gutterBottom sx={{ pb: 4 }}>
              SOBRE NOSOTROS
            </Typography>
            <Typography color='white' variant='body1'>
              Rúbrica es el primer café de especialidad personalizado. Desarrollamos <b><i>Coffee Wizard</i></b>, un algoritmo que detecta y perfila tus gustos para transformarlos en algo único. Somos una empresa 100% mexicana.
            </Typography>
          </Grid>
          <Grid item xs={12} md={3}>
            <Typography color='white' variant='h5' gutterBottom sx={{ pb: 4 }}>
              NAVEGACIÓN
            </Typography>
            <Typography color='white' variant='body1' gutterBottom>
              <a href='https://rubrica.mx/' style={{ color: 'white', textDecoration: 'none' }}>Inicio</a>
            </Typography>
            <Typography color='white' variant='body1' gutterBottom>
              <a href='https://rubrica.mx/personalidad' style={{ color: 'white', textDecoration: 'none' }}>Nuestra personalidad</a>
            </Typography>
            <Typography color='white' variant='body1' gutterBottom>
              <a href='https://rubrica.mx/faq' style={{ color: 'white', textDecoration: 'none' }}>FAQ</a>
            </Typography>
            <Typography color='white' variant='body1' gutterBottom>
              <a href='https://rubrica.mx/contacto' style={{ color: 'white', textDecoration: 'none' }}>Contacto</a>
            </Typography>
          </Grid>
          <Grid item xs={12} md={3}>
            <Typography color='white' variant='h5' gutterBottom sx={{ pb: 4 }}>
              CONTACTO
            </Typography>
            <Typography color='white' variant='body1'>
            <a href='mailto:contacto@rubrica.mx' style={{ color: 'white', textDecoration: 'none' }}>contacto@rubrica.mx</a>
            </Typography>
          </Grid>
        </Grid>
        {/* <Divider sx={{ '&::before, &::after': { borderColor: 'white' }, }} /> */}
        <Divider sx={{  borderColor: 'white', borderBottomWidth: 3, }} />
        <Grid container align='center'>
          <Grid item xs={12}>
            <Box
              component='img' 
              src='/assets/images/logo-letras.png'
              alt='Rubrica logo'
              sx= {{ height: 48, mt: 4 }}
            />
          </Grid>
        </Grid>
        <Box textAlign='center' pt={4}>
          <Typography color='white' align='center' variant='body1'>
            © 2022 Taller Rúbrica S.A de C.V. Todos los derechos reservados. Conoce nuestro <a href='https://rubrica.mx/aviso-privacidad' style={{ color: 'white', textDecoration: 'none' }}><b>Aviso de Privacidad</b></a> y <a href='https://rubrica.mx/terminos-y-condiciones' style={{ color: 'white', textDecoration: 'none' }}><b>Términos y Condiciones</b></a>.
          </Typography>
        </Box>
      </Box>
      <ScrollTop {...props}>
        <Fab color='secondary' size='small'>
          <KeyboardArrowUpIcon />
        </Fab>
      </ScrollTop>
    </Box>
  )
}

export default Landing
