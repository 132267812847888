import React from 'react'

import { 
  Box,
  Divider,
  Grid,
  Typography,
} from '@mui/material'

import Logo from 'pages/wizard/logo'

const Label = (props) => {
  const { newName, data } = props
  const { name, blue, orange, red, yellow, rubrica } = data

  return (
    <Box sx={{ p: 0 }}>
      <Box sx={{ borderColor: '#9C8500', borderStyle: 'dotted', borderWidth: 4, p: 1 }}>
        <Grid container align='center' sx={{ borderColor: '#9C8500', borderStyle: 'solid', borderWidth: 2 , p: 2 }}>
          <Grid item xs={12}>
            <Box align='center' sx={{ height: 128 }}>
              <Logo final blue={blue} orange={orange} red={red} yellow={yellow} />
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Box
              component='img'
              src='/assets/images/logo_rubrica_letras.png'
              width='60%'
            />
          </Grid>
          <Grid item xs={12}>
            <Typography variant='h5' sx={{ my: 2 }}>
              DE
            </Typography>
            <Typography fontFamily='AntroVectra' variant='h4' sx={{ mt: 2 }}>
              {newName !== undefined ? newName : name}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Divider sx={{ borderBottomWidth: 'unset', borderColor: '#9C8500', opacity: 1, my: 2, width: '60%' }} />
          </Grid>
          <Grid item xs={2}></Grid>
          <Grid item xs={8}>
            <Grid container align='left'>
              <Grid item xs={6}>
                <Typography variant='body2'><b>Varietal:</b></Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant='body2'>{rubrica.varietal}</Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant='body2'><b>Proceso:</b></Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant='body2'>{rubrica.proceso}</Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant='body2'><b>Grupo aromático:</b></Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant='body2'>{rubrica.aromatico}</Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant='body2'><b>Nota específicas:</b></Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant='body2'>{rubrica.notas}</Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant='body2'><b>Origen:</b></Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant='body2'>{rubrica.origen}</Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={2}></Grid>
          <Grid item xs={12}>
            <Divider sx={{ borderBottomWidth: 'unset', borderColor: '#9C8500', opacity: 1, my: 2, width: '60%' }} />
          </Grid>
          <Grid item xs={12}>
            <Typography variant='body1' gutterBottom>Contenido: 340 grs de café tostado</Typography>
            <Typography variant='h6' sx={{ color: '#9C8500' }} gutterBottom>CAFÉ DE ESPECIALIDAD PERSONALIZADO</Typography>
            <Typography variant='body1'><b>www.rubrica.mx</b></Typography>
          </Grid>
        </Grid>
      </Box>
    </Box>
    // <div style={{ padding: '64px', textAlign: 'center', width: '50%' }}>
    //   <img src='/assets/images/logo.png' width='50%'/>
    //   <div style={{ fontSize: 32, paddingTop: '8px' }}><span>DE</span></div>
    //   <div style={{ fontFamily: 'RockSalt', fontSize: 32, padding: '16px' }}>Gonzalo Linares</div>
    //   <div style={{ display: 'flex', textAlign: 'left' }}>
    //     <div style={{ flex: '50%' }}>Hola</div>
    //     <div style={{ flex: '50%' }}>Mundo</div>
    //   </div>
    //   <div style={{ fontSize: 18, paddingBottom: '4px' }}>Contenido: 340 grs de café tostado</div>
    //   <div style={{ color: '#9C8500', fontSize: 20 }}>CAFÉ DE ESPECIALIDAD PERSONALIZADO</div>
    //   <div style={{ fontSize: 20 }}><b>www.rubrica.mx</b></div>
    // </div>
  )
}

export default Label