import React, { useState } from 'react'

import {
  FormControl,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
} from '@mui/material'

const Machine = (props) => {
  const { current, getCurrentValue } = props

  const values = {
    'coffee_1': 3,
    'coffee_2': 2,
    'coffee_3': 0,
    'coffee_4': 1.5,
    'coffee_5': 1,
    'coffee_6': 2.5,
    // 'coffee_7': -1,
  }
  
  const [value, setValue] = useState(current !== undefined ? current.id : '')
  //   current === undefined ? '' : Object.keys(values).find(key => values[key] === current)
  // )

  const handleRadioChange = (event) => {
    setValue(event.target.value)
    getCurrentValue({
      'id': event.target.value,
      'value': values[event.target.value],
    })
  }

  return (
    // <FormControl style={{ display: 'grid', gridTemplateColumns: 'repeat(2, 1fr)'}}>
    <Grid container alignContent='center' alignItems='center' direction='column' justifyContent='center'>
      <Grid item xs={12}>
        <FormControl>
          <RadioGroup value={value} onChange={handleRadioChange}>
            <FormControlLabel value='coffee_1' control={<Radio />} label='Máquina de espresso con molino' />
            <FormControlLabel value='coffee_2' control={<Radio />} label='Máquina de espresso sin molino' />
            <FormControlLabel value='coffee_3' control={<Radio />} label='Máquina de filtro' />
            <FormControlLabel value='coffee_4' control={<Radio />} label='Dripper / Pour Over' />
            <FormControlLabel value='coffee_5' control={<Radio />} label='Prensa Francesa' />
            <FormControlLabel value='coffee_6' control={<Radio />} label='Moka Italiana' />
            {/* <FormControlLabel value='coffee_7' control={<Radio />} label='Otro' /> */}
          </RadioGroup>
        </FormControl>
      </Grid>
    </Grid>
    
  )
}

export default Machine