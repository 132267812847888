import React, { useState } from 'react'

import {
  FormControl,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
} from '@mui/material'

const Tea = (props) => {
  const { current, getCurrentValue } = props

  const values = {
    'tea_1': 5,
    'tea_2': 4.5,
    'tea_3': 4,
    'tea_4': 3.5,
    'tea_5': 3,
    'tea_6': 2,
    'tea_7': 1,
  }
  
  const [value, setValue] = useState(current !== undefined ? current.id : '')

  const handleRadioChange = (event) => {
    setValue(event.target.value)
    getCurrentValue({
      'id': event.target.value,
      'value': values[event.target.value],
    })
  }

  return (
    <Grid container alignContent='center' alignItems='center' direction='column' justifyContent='center'>
      <Grid item xs={12}>
        <FormControl>
          <RadioGroup value={value} onChange={handleRadioChange}>
            <FormControlLabel value='tea_1' control={<Radio />} label='Negro, Darjeeling, Earl Grey' />
            <FormControlLabel value='tea_2' control={<Radio />} label='Chai, Ceylon' />
            <FormControlLabel value='tea_3' control={<Radio />} label='Infusión herbal, Té verde, Matcha' />
            <FormControlLabel value='tea_4' control={<Radio />} label='Té de limón, Lemongrass o Cedro' />
            <FormControlLabel value='tea_5' control={<Radio />} label='Infusión de moras' />
            <FormControlLabel value='tea_6' control={<Radio />} label='Tizana frutal' />
            <FormControlLabel value='tea_7' control={<Radio />} label='Té de jazmín, Té de azahar' />
          </RadioGroup>
        </FormControl>
      </Grid>
    </Grid>
    
  )
}

export default Tea