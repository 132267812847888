import { createTheme } from '@mui/material/styles'
import red from '@mui/material/colors/red'
import { esES } from '@mui/material/locale'

// 222222 - Eerie Black
// 252627 - Raising Black 373f47

const theme = createTheme({
  root: {
    '& .MuiDataGrid-cell:focus-within, & .MuiDataGrid-cell:focus': {
      outline: 'none'
    },
  },
  components: {
    // MuiFormLabel: {
    //   styleOverrides: {
    //     root: {
    //       color: '#d3d3d7',
    //     }
    //   }
    // },
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: 'none'
        }
      }
    },
    MuiCard: {
      styleOverrides: {
        root: {
          boxShadow: 'none',
        }
      },
    },
    MuiCssBaseline: {
      styleOverrides: {
        fallbacks: [
          {
            '@font-face': {
              fontFamily: 'Righteous',
              src: `local('Righteous'), local('Righteous'), url('/assets/fonts/Righteous-Regular.ttf')`,
            },
          },
          {
            '@font-face': {
              fontFamily: 'RockSalt',
              src: `local('RockSalt'), local('RockSalt'), url('/assets/fonts/RockSalt-Regular.ttf')`,
            },
          },
          {
            '@font-face': {
              fontFamily: 'AntroVectra',
              src: `local('AntroVectra'), local('AntroVectra'), url('/assets/fonts/Antro-Vectra.otf')`,
            },
          }
        ],
        body: {
          // backgroundImage: 'url(assets/images/logo.png)',
          overflow: 'auto !important',
          padding: '0 !important',
          overflowX: 'hidden'
        }
      }
    },
  },
  palette: {
    primary: {
      // main: '#373f47',
      main: '#333'
      // main: '#5f6caf',
    },
    secondary: {
      main: '#933d31',
    },
    error: {
      main: red.A400,
    },
    background: {
      // default: '#f8f9fc',// '#f0f0f0',
      default: '#fff',
      alternative: '#f8f9fc'
    },
    text: {
      primary: '#535461',
      secondary: '#90909a' //'#d3d3d7'
    },
    rubricaBlue: {
      main: '#608080',
      contrastText: '#fff',
    },
    rubricaRed: {
      main: '#933d31',
      contrastText: '#fff',
    },
    rubricaYellow: {
      main: '#cc9a36',
      contrastText: '#fff',
    },
    rubricaOrange: {
      main: '#d58d3f',
      contrastText: '#fff',
    },
    rubricaWhite: {
      main: '#fff',
      contrastText: '#000',
    },
    rubricaGreen: {
      main: '#09814A',
      dark: '#09814A',
      light: '#09814A',
      contrastText: '#fff',
    },
  },
  typography: {
    fontFamily: 'Avenir, sans-serif',
  }
}, esES)

export default theme