import React, { useEffect, useState } from 'react'

import { useParams } from 'react-router-dom'

import useAuth from 'hooks/auth'

import {
  Alert,
  Backdrop,
  Box,
  Button,
  CircularProgress,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  Divider,
  FormControl,
  Grid,
  InputLabel,
  Link,
  MenuItem,
  OutlinedInput,
  Select,
  TextField,
  Typography,
} from '@mui/material'

import { MyLocation } from '@mui/icons-material'

import InputMask from 'react-input-mask'

import { Amex, Mastercard, Visa } from 'react-pay-icons'

import { format } from 'date-fns'
import { es } from 'date-fns/locale'


const cards = {
  'Mastercard': <Mastercard style={{ height: 28 }} />,
  'Visa': <Visa style={{ height: 28 }} />,
  'Visa Electron': <Visa style={{ height: 28 }} />,
  'American Express': <Amex style={{ height: 28 }} />
}

const Order = (props) => {
  const params = useParams()
  const { id } = params

  const { firestore, facturama } = useAuth()

  const [order, setOrder] = useState()

  const [open, setOpen] = useState(false)
  const [openDialog, setOpenDialog] = useState(false)
  const [error, setError] = useState('')
  const [success, setSuccess] = useState('')


  const ALPHA_NUMERIC_DASH_REGEX = /[A-Za-z ñ]+$/

  const [invoice, setInvoice] = useState({
    'name': '',
    'email': '',
    'rfc': '',
    'code': '',
    'payment': '04',
    'regimen': '612',
    'use': 'P01'
  })
  const handleInvoiceChange = (prop) => (event) => {
    let value = event.target.value
    if (prop === 'name') {
      // value = value.replace(/[^\x00-\x7F]/ig, '').toUpperCase()
      if (value !== '' && !ALPHA_NUMERIC_DASH_REGEX.test(value)) {
        return
      }
      value = value.toUpperCase()
    }
    setInvoice({ ...invoice, [prop]: value })
  }

  useEffect(() => {
    const getOrder = async () => {
      const order = await firestore.getOrder(id)
      setOrder(order)
    }

    getOrder()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (order === undefined) return <div></div>

  const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  const onRequestInvoice = async () => {
    setError('')
    setOpenDialog(false)
    setOpen(true)
    try {
      const data = await facturama.requestInvoice({
        'name': invoice['name'].toUpperCase(),
        'rfc': invoice['rfc'],
        'email': invoice['email'],
        'code': invoice['code'],
        'use': invoice['use'],
        'regimen': invoice['regimen'],
        'payment': invoice['payment']
      }, {
        'id': order['id'],
        'qty': order['data']['rubrica']['bolsas'],
        'description':`Bolsa${parseInt(order['data']['rubrica']['bolsas']) === 1 ? '' : 's'} de café en grano ${order['data']['rubrica']['grano']} de 340grs.`,
        'amount': order['amount'],
        'unit': order['data']['rubrica']['costo'],
        'shipping': order['shipping']['cost'],
      })
  
      if (data['Id'] !== undefined) {
        await firestore.updateOrder(order['id'], {
          'invoiceId': data['Id']
        })
      }
      setSuccess('En cuanto esté lista la factura la recibirás en el correo proporcionado')
    } catch(error) {
      setError(error)
    }

    setOpen(false)
  }

  const handleClose = () => {
    setOpenDialog(false)
  }

  // const requestShipment = async (order) => {
  //   const address = order['shipping']['address']
  //   const customer = order['customer']
  //   const quantity = order['data']['rubrica']['bolsas']

  //   const data = await functions.requestShipment({
  //     'id': order['id'],
  //     'address': address,
  //     'customer': {
  //       'email': customer['email'],
  //       'name': customer['name']
  //     },
  //     'package': {
  //       'height': 17,
  //       'length': 7,
  //       'width': 12 * quantity,
  //       'weight': 0.34 * quantity
  //     },
  //     'cost': order['amount']
  //   })

  //   console.log(data)

  //   await firestore.updateOrder(order['id'], {
  //     'shipment': data,
  //   })
  // }

  // const fix = async () => {
  //   const timestamp = new Date()
  //   let newOrder = Object.assign({}, order)
  //   newOrder['printed'] = false
  //   newOrder['createdAt'] = timestamp
  //   newOrder['updatedAt'] = timestamp
  //   delete newOrder['shipment']

  //   const orderId = await firestore.createOrder(newOrder)
  //   newOrder['id'] = orderId

  //   await requestShipment(newOrder)
  // }

  return (
    <Box>
      <Container maxWidth='sm' sx={{ bgcolor: 'white', p: 2 }}>
        <Grid container spacing={2}>
          <Grid item xs={8}>
            <Typography variant='h5'>
              <b>Orden: {id.slice(-10).toUpperCase()}</b>
            </Typography>
            <Typography variant='body2' sx={{ color: '#999' }}>
              Fecha de la compra: {capitalizeFirstLetter(format(new Date(order['createdAt'].seconds * 1000), 'MMM d, yyyy', { locale: es }))}
            </Typography>
          </Grid>
          <Grid item xs={12}><Divider /></Grid>
          <Grid item xs={12}>
            <Grid container alignItems='center' sx={{ py: 2 }}>
              <Grid item xs={2} md={1}>
                <Typography variant='body1'>
                  <b>{order['data']['rubrica']['bolsas']}</b>
                </Typography>
              </Grid>
              <Grid item xs={8} md={8}>
                <Typography variant='body1'>
                  Bolsa{order['data']['rubrica']['bolsas'] === 1 ? '' : 's'} de café en grano {order['data']['rubrica']['grano']} de 340grs.
                </Typography>
              </Grid>
              <Grid item xs={2} md={3} align='right'>
                <Typography variant='body1'>
                  <b>${order['data']['rubrica']['costo']}</b>
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}><Divider /></Grid>
          <Grid item xs={12} md={6}>
            <Grid container spacing={2} alignItems='center'>
              <Grid item xs={12}>
                <Typography variant='h6'>
                  Pago
                </Typography>
              </Grid>
              <Grid item xs={3}>
                {cards[order['payment']['type']]}
              </Grid>
              <Grid item xs={9}>
                <Typography variant='body2'>
                  •••• {order['payment']['number']}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography variant='h6'>
              Dirección de envío
            </Typography>
            <Typography variant='body2'>
              {order['shipping']['address']['street']}
            </Typography>
            <Typography variant='body2'>
              {order['shipping']['address']['city']}, {order['shipping']['address']['city']}
            </Typography>
            <Typography variant='body2'>
              {order['shipping']['address']['code']}
            </Typography>
          </Grid>
          <Grid item xs={12}><Divider /></Grid>
          <Grid item xs={9} align='right'>
            <Typography variant='body1' style={{ color: '#777' }}>
              Subtotal:
            </Typography>
          </Grid>
          <Grid item xs={3} align='right'>
            <Typography variant='body1' style={{ color: '#777' }}>
              ${order['data']['rubrica']['bolsas'] * order['data']['rubrica']['costo']}
            </Typography>
          </Grid>
          <Grid item xs={9} align='right'>
            <Typography variant='body1' style={{ color: '#777' }}>
              Descuento {order['payment']['coupon'] !== undefined ? `(${order['payment']['coupon']['discount'] * 100}%)` : ''}:
            </Typography>
          </Grid>
          <Grid item xs={3} align='right'>
            <Typography variant='body1' style={{ color: '#777' }}>
              {order['payment']['coupon'] !== undefined ? `- $${order['payment']['coupon']['type'] === 'bag' ? order['payment']['coupon']['discount'] * order['data']['rubrica']['costo'] : order['payment']['coupon']['discount'] * (order['data']['rubrica']['bolsas'] * order['data']['rubrica']['costo'])}` : '-'}
            </Typography>
          </Grid>
          <Grid item xs={9} align='right'>
            <Typography variant='body1' style={{ color: '#777' }}>
              Envío + IVA:
            </Typography>
          </Grid>
          <Grid item xs={3} align='right'>
            <Typography variant='body1' style={{ color: '#777' }}>
              + ${(order['shipping']['cost'] * 1.16).toFixed(2)}
            </Typography>
          </Grid>
          <Grid item xs={9} align='right'>
            <Typography variant='h6'>
              <b>Total:</b>
            </Typography>
          </Grid>
          <Grid item xs={3} align='right'>
            <Typography variant='h6'>
              <b>${order['amount']}</b>
            </Typography>
          </Grid>
          <Grid item xs={12}><Divider /></Grid>
          <Grid item xs={12}>
            <Typography variant='caption' style={{ color: '#777' }}>
              <i>*Recibirás tu café en un periodo de 7 a 11 días a partir de la fecha de tu pedido.</i>
            </Typography>
          </Grid>
          <Grid item xs={6} align='right'>
            {
              order['shipment'] !== undefined ? (
                <Button fullWidth component={Link} target='_blank' href={order['shipment']['tracking_link']} startIcon={<MyLocation />} variant='contained' disableElevation size='small'>
                  Seguir envío
                </Button>
              ) : null
            }
          </Grid>
          <Grid item xs={6}>
            <Button disabled={order['invoiceId'] !== undefined || success !== ''} fullWidth variant='contained' disableElevation size='small' onClick={() => setOpenDialog(true)}>
              Solicitar factura
            </Button>
          </Grid>
          {/* <Grid item xs={6}>
            <Button fullWidth variant='contained' disableElevation size='small' onClick={() => { fix() }}>
              Arreglar
            </Button>
          </Grid> */}
          {
            error !== '' ? (
              <Grid item xs={12}>
                <Alert severity='error'>{error}</Alert>
              </Grid>
            ) : null
          }
          {
            success !== '' ? (
              <Grid item xs={12}>
                <Alert severity='success'>{success}</Alert>
              </Grid>
            ) : null
          }
        </Grid>
      </Container>
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={open}
      >
        <CircularProgress color='inherit' />
      </Backdrop>
      <Dialog open={openDialog} onClose={handleClose}>
        <DialogContent sx={{ p: 2 }}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography variant='h5'>Facturación</Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant='caption' style={{ color: '#777' }}>
                <i>*Para poder timbrar la factura en versión 4.0 los datos proporcionados deben estar exactamente como viene en su Constancia Fiscal (sin acentos, con puntos y comas).</i>
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <TextField InputLabelProps={{ shrink: true }} value={invoice['name']} onChange={handleInvoiceChange('name')} label='Nombre' variant='outlined' fullWidth/>
            </Grid>
            <Grid item xs={12}>
              <TextField InputLabelProps={{ shrink: true }} value={invoice['email']} onChange={handleInvoiceChange('email')} label='Email' type='email' variant='outlined' fullWidth/>
            </Grid>
            <Grid item xs={12}>
              <TextField InputLabelProps={{ shrink: true }} value={invoice['rfc']} onChange={handleInvoiceChange('rfc')} label='RFC' variant='outlined' fullWidth/>
            </Grid>
            <Grid item xs={12}>
              <FormControl fullWidth variant='outlined'>
                <InputLabel shrink id='select-outlined-payment'>Forma de pago</InputLabel>
                <Select
                  labelId='select-outlined-payment'
                  id='select-outlined-payment'
                  onChange={handleInvoiceChange('payment')}
                  value={invoice['payment']}
                  label='Forma de pago'
                  notched
                >
                  <MenuItem value='04'>
                    Tarjeta de crédito
                  </MenuItem>
                  <MenuItem value='28'>
                    Tarjeta de débito
                  </MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <FormControl fullWidth variant='outlined'>
                <InputLabel shrink id='select-outlined-use'>Uso de la factura</InputLabel>
                <Select
                  labelId='select-outlined-use'
                  id='select-outlined-use'
                  onChange={handleInvoiceChange('use')}
                  value={invoice['use']}
                  label='Uso de la factura'
                  notched
                >
                  <MenuItem value='P01'>
                    P01 Por definir
                  </MenuItem>
                  <MenuItem value='G01'>
                    G01 Adquisición de mercancias
                  </MenuItem>
                  <MenuItem value='G03'>
                    G03 Gastos en general
                  </MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <FormControl fullWidth variant='outlined'>
                <InputLabel shrink id='select-outlined-regimen'>Régimen fiscal</InputLabel>
                <Select
                  labelId='select-outlined-regimen'
                  id='select-outlined-regimen'
                  onChange={handleInvoiceChange('regimen')}
                  value={invoice['regimen']}
                  label='Régimen fiscal'
                  notched
                >
                  <MenuItem value='601'>
                    601 General de Ley Personas Morales
                  </MenuItem>
                  <MenuItem value='603'>
                    603 Personas Morales con Fines no Lucrativos
                  </MenuItem>
                  <MenuItem value='605'>
                    605 Sueldos y Salarios e Ingresos Asimilados a Salarios
                  </MenuItem>
                  <MenuItem value='606'>
                    606 Arrendamiento
                  </MenuItem>
                  <MenuItem value='607'>
                    607 Régimen de Enajenación o Adquisición de Bienes
                  </MenuItem>
                  <MenuItem value='608'>
                    608 Demás ingresos
                  </MenuItem>
                  <MenuItem value='609'>
                    609 Consolidación
                  </MenuItem>
                  <MenuItem value='611'>
                    611 Ingresos por Dividendos (socios y accionistas)
                  </MenuItem>
                  <MenuItem value='612'>
                    612 Personas Físicas con Actividades Empresariales y Profesionales
                  </MenuItem>
                  <MenuItem value='614'>
                    614 Ingresos por intereses
                  </MenuItem>
                  <MenuItem value='615'>
                    615 Régimen de los ingresos por obtención de premios
                  </MenuItem>
                  <MenuItem value='616'>
                    616 Sin obligaciones fiscales
                  </MenuItem>
                  <MenuItem value='620'>
                    620 Sociedades Cooperativas de Producción que optan por Diferir sus Ingresos
                  </MenuItem>
                  <MenuItem value='621'>
                    621 Incorporación Fiscal
                  </MenuItem>
                  <MenuItem value='622'>
                    622 Actividades Agrícolas, Ganaderas, Silvícolas y Pesqueras
                  </MenuItem>
                  <MenuItem value='623'>
                    623 Opcional para Grupos de Sociedades
                  </MenuItem>
                  <MenuItem value='624'>
                    624 Coordinados
                  </MenuItem>
                  <MenuItem value='628'>
                    628 Hidrocarburos
                  </MenuItem>
                  <MenuItem value='629'>
                    629 De los Regímenes Fiscales Preferentes y de las Empresas Multinacionales
                  </MenuItem>
                  <MenuItem value='630'>
                    630 Enajenación de acciones en bolsa de valores
                  </MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <FormControl fullWidth variant='outlined'>
                <InputLabel shrink htmlFor='postal-code'>Código Postal</InputLabel>
                <InputMask
                  mask='99999'
                  maskChar=''
                  value={invoice['code']}
                  onChange={handleInvoiceChange('code')}
                >
                  { (inputProps) => <OutlinedInput {...inputProps} id='postal-code' label='Código Postal' name='number' notched /> }
                </InputMask>
              </FormControl>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions sx={{ p: 2, pt: 0 }}>
          <Button onClick={handleClose}>Cancelar</Button>
          <Button disabled={invoice['name'] === '' || invoice['email'] === '' || invoice['rfc'] === '' || invoice['code'] === ''} variant='contained' disableElevation color='primary' onClick={onRequestInvoice}>Solicitar</Button>
        </DialogActions>
      </Dialog>
    </Box>
  )
}

export default Order