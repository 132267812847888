import React, { useEffect, useState } from 'react'

import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Container,
  Typography,
} from '@mui/material'

import LinearProgress from '@mui/material/LinearProgress'

import Machine from './yellow/machine'
import Milk from './yellow/milk'
import Brand from './yellow/brand'
import Recipe from './yellow/recipe'
import Sugar from './yellow/sugar'
import Moment from './yellow/moment'
import Cup from './yellow/cup'
import Logo from './logo'
import Chocolate from './blue/chocolate'
import Wine from './blue/wine'
import Dessert from './blue/dessert'
import Perfume from './blue/perfume'
import Drink from './red/drink'
import Tea from './red/tea'
import Aroma from './red/aroma'
import Flavor from './red/flavor'
import Quantity from './orange/quantity'
import Grain from './orange/grain'
import Result from './result'
import backgrounds from './backgrounds'

import { useCalculations } from './calculation'
import Summary from './summary'

const Wizard = (props) => {
  const [step, setStep] = useState(0)
  const [progress, setProgress] = useState(0)
  const [disabled, setDisabled] = useState(false)

  const { calculateAroma, calculateRubrica } = useCalculations()

  const [data, setData] = useState()
  
  const [yellow, setYellow] = useState(0)
  const [yellowValues, setYellowValues] = useState({})

  const [blue, setBlue] = useState(0)
  const [blueValues, setBlueValues] = useState({})
  const [orange, setOrange] = useState(0)
  const [orangeValues, setOrangeValues] = useState({})
  const [red, setRed] = useState(0)
  const [redValues, setRedValues] = useState({})

  const [selectedRecipes, setSelectedRecipes] = useState([])

  const [rubrica, setRubrica] = useState({})

  const getCurrentYellowValue = (value) => {
    setYellowValues(data => ({...data, [step]: value}))
  }

  const getCurrentBlueValue = (value) => {
    setBlueValues(data => ({...data, [step]: value}))
  }

  const getCurrentRedValue = (value) => {
    setRedValues(data => ({...data, [step]: value}))
  }

  const getCurrentOrangeValue = (value) => {
    setOrangeValues(data => ({...data, [step]: value}))
  }

  const recipes = {
    recipe_1: 1, recipe_2: 0.5, recipe_3: 0.5, recipe_4: 1, recipe_5: 1, recipe_6: 0, recipe_7: 1, recipe_8: 0.5, recipe_9: 0, recipe_10: 0, recipe_11: 0, recipe_12: 1, recipe_13: 0.5
  }

  const calculateYellow = () => {
    const formatted = Object.assign({}, yellowValues)

    if (formatted[3] !== undefined) {
      setSelectedRecipes(Object.keys(formatted[3]).filter((key) => formatted[3][key]))
      formatted[3] = {
        'id': 'recipes',
        'value':  Object.keys(formatted[3]).filter((key) => formatted[3][key]).map((key) => recipes[key]).reduce((a, b) => a + b, 0)
      }
    }

    const sum = Object.values(formatted).map((item) => item.value).reduce((a, b) => a + b, 0)
    const total = sum * 32 / 20
    setYellow(total > 32 ? 32 : total)

    if (step !== 3) {
      setDisabled(yellowValues[step] === undefined)
    } else {
      setDisabled(yellowValues[step] !== undefined ? Object.values(yellowValues[step]).filter(Boolean).length !== 4 : false)
    }
  }

  const calculateBlue = () => {
    const formatted = Object.assign({}, blueValues)
    const sum = Object.values(formatted).map((item) => item.value).reduce((a, b) => a + b, 0)
    const total = sum * 32 / 20
    setBlue(total > 32 ? 32 : total)
    setDisabled(blueValues[step] === undefined)
  }

  const calculateRed = () => {
    const formatted = Object.assign({}, redValues)
    const sum = Object.values(formatted).map((item) => item.value).reduce((a, b) => a + b, 0)
    const total = sum * 32 / 20
    setRed(total > 32 ? 32 : total)
    setDisabled(redValues[step] === undefined)
  }

  const calculateOrange = () => {
    const formatted = Object.assign({}, orangeValues)
    const sum = Object.values(formatted).map((item) => item.value).reduce((a, b) => a + b, 0)
    const total = sum * 32 / 20
    setOrange(total > 32 ? 32 : total)
    setDisabled(orangeValues[step] === undefined)
  }

  useEffect(() => {
    setProgress(step * 100 / (items.length - 1))

    if (step > 0 && step < 8) {
      calculateYellow()
    } else if (step > 7 && step < 12) {
      calculateBlue()
    } else if (step > 11 && step < 16) {
      calculateRed()
    } else if (step > 15 && step < 18) {
      calculateOrange()
    }

    if (step === (items.length - 2)) {
      const rubrica = calculateRubrica(blueValues, orangeValues, redValues, yellowValues, selectedRecipes, orangeValues[17] !== undefined ? orangeValues[17].id : '')
      setRubrica(rubrica)

      setData({ blue, orange, red, yellow, rubrica })
    }

   // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [step, blueValues, orangeValues, redValues, yellowValues])

  useEffect(() => {
    window.scrollTo(0,0)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [step])


  const items = [
    { 
      'title': <b>EMPECEMOS A CREAR <span style={{ color: '#608080' }}>TU RÚBRICA</span><br/></b>,
      'subtitle': '',
      'content': <div></div>,
      // 'content': <TextField label='Mi nombre es:' variant='outlined' fullWidth onChange={handleNameChange} value={name} />,
    },
    // YELLOW
    { 
      'title': <b>¡HOLA! YO SOY EL <span style={{ color: '#cc9a36' }}>COFFEE WIZARD®</span> DE <span style={{ color: '#cc9a36' }}>RÚBRICA</span> Y TE VOY A AYUDAR A CREAR ALGO ÚNICO.</b>,
      'subtitle': 'Usualmente ¿Qué método usas para preparar tu café?',
      'content': <Machine current={yellowValues[step]} getCurrentValue={getCurrentYellowValue} />,
    },
    { 
      'title': <b>¿QUÉ <span style={{ color: '#cc9a36' }}>MARCA</span> ES LA QUE MÁS UTILIZAS?</b>,
      'subtitle': `Máquina de espresso ${yellowValues[step-1] !== undefined ? (yellowValues[step-1].id === 'coffee_2' ? 'sin' : 'con') : 'con'} molino integrado:`,
      'content': <Brand current={yellowValues[step]} getCurrentValue={getCurrentYellowValue} machine={yellowValues[step-1] !== undefined ? yellowValues[step-1].id : ''} />,
    },
    { 
      'title': <b>¿CUÁL ES TU <span style={{ color: '#cc9a36' }}>RECETA FAVORITA</span><br/>PARA BEBER CAFÉ?</b>,
      'subtitle': 'Selecciona 4',
      'content': <Recipe current={yellowValues[step]} getCurrentValue={getCurrentYellowValue} />,
    },
    { 
      'title': <b>¿ACOSTUMBRAS BEBER TU CAFÉ<br/>CON <span style={{ color: '#cc9a36' }}>LECHE</span> O CON ALGÚN <span style={{ color: '#cc9a36' }}>CREMADOR</span>?</b>,
      'subtitle': '',
      'content': <Milk current={yellowValues[step]} getCurrentValue={getCurrentYellowValue} />,
    },
    { 
      'title': <b>¿LE PONES <span style={{ color: '#cc9a36' }}>AZÚCAR</span> O ALGÚN<br/>TIPO DE <span style={{ color: '#cc9a36' }}>ENDULZANTE</span>?</b>,
      'subtitle': '',
      'content': <Sugar current={yellowValues[step]} getCurrentValue={getCurrentYellowValue} />,
    },
    { 
      'title': <b>SÍ TOMAS CAFÉ EN DISTINTOS<br/><span style={{ color: '#cc9a36' }}>MOMENTOS DEL DÍA</span>, ¿CUÁL ES EL MÁS COMÚN?</b>,
      'subtitle': '',
      'content': <Moment current={yellowValues[step]} getCurrentValue={getCurrentYellowValue} />,
    },
    { 
      'title': <b>¿DE QUÉ <span style={{ color: '#cc9a36' }}>TAMAÑO</span> ES LA TAZA DE TU PREFERENCIA?</b>,
      'subtitle': 'Todos tenemos una taza de café favorita. La mía es:',
      'content': <Cup current={yellowValues[step]} getCurrentValue={getCurrentYellowValue} />,
    },
    // BLUE
    { 
      'title': <b>¿CÓMO TE GUSTA MÁS UN <span style={{ color: '#608080' }}>CHOCOLATE</span>?</b>,
      'subtitle': 'Vamos a conocer mejor tus gustos en distintas categorías',
      'content': <Chocolate current={blueValues[step]} getCurrentValue={getCurrentBlueValue} />,
    },
    { 
      'title': <b>¿CUÁL ES TU <span style={{ color: '#608080' }}>GUSTO EN VINOS</span>?</b>,
      'subtitle': 'Mi tipo de uva preferida es:',
      'content': <Wine current={blueValues[step]} getCurrentValue={getCurrentBlueValue} />,
    },
    { 
      'title': <b>¿CUÁL ELEGIRÍAS EN UNA <span style={{ color: '#608080' }}>SOBREMESA</span>?</b>,
      'subtitle': 'Hablemos de postres',
      'content': <Dessert current={blueValues[step]} getCurrentValue={getCurrentBlueValue} />,
    },
    { 
      'title': <b>¿CÓMO DESCRIBIRÍAS TU <span style={{ color: '#608080' }}>LOCIÓN</span>/<span style={{ color: '#608080' }}>PERFUME</span> FAVORITO?</b>,
      'subtitle': '',
      'content': <Perfume current={blueValues[step]} getCurrentValue={getCurrentBlueValue} />,
    },
    // RED
    { 
      'title': <b>¿CUÁL ES EL <span style={{ color: '#933d31' }}>DIGESTIVO</span> DE TU PREFERENCIA?</b>,
      'subtitle': 'Para cuando la ocasión lo amerita...',
      'content': <Drink current={redValues[step]} getCurrentValue={getCurrentRedValue} />,
    },
    { 
      'title': <b>EN CASO DE PEDIR ALGÚN <span style={{ color: '#933d31' }}>TÉ</span> PARA CERRAR UNA COMIDA, ¿CUÁL ELIJES?</b>,
      'subtitle': '',
      'content': <Tea current={redValues[step]} getCurrentValue={getCurrentRedValue} />,
    },
    { 
      'title': <b>¿CUÁL CONSIDERARÍAS QUE ES EL GRUPO <span style={{ color: '#933d31' }}>AROMÁTICO</span> IDEAL PARA TI?</b>,
      'subtitle': 'Ya casi terminamos...',
      'content': <Aroma current={redValues[step]} getCurrentValue={getCurrentRedValue} />,
    },
    { 
      'title': <b>¿CUÁL CONSIDERAS <span style={{ color: '#933d31' }}>QUE APRECIAS MÁS</span>?</b>,
      'subtitle': 'Elige una opción de nota:',
      'content': <Flavor aroma={calculateAroma(blueValues, orangeValues, redValues, yellowValues, selectedRecipes)} current={redValues[step]} getCurrentValue={getCurrentRedValue} />,
      // 'content': <Flavor aroma={redValues[step-1] !== undefined ? redValues[step-1].id : ''} current={redValues[step]} getCurrentValue={getCurrentRedValue} />,
    },
    // ORANGE
    { 
      'title': <b><span style={{ color: '#d58d3f' }}>¿CUÁNTAS TAZAS</span> DE CAFÉ TOMAS AL DÍA?</b>,
      'subtitle': '',
      'content': <Quantity current={orangeValues[step]} getCurrentValue={getCurrentOrangeValue} />,
    },
    { 
      'title': <b>¿DESEAS QUE TE ENVIEMOS EL <span style={{ color: '#d58d3f' }}>GRANO MOLIDO</span>?</b>,
      'subtitle': 'Ya estamos preparando tu mezcla personalizada.',
      'content': <Grain current={orangeValues[step]} getCurrentValue={getCurrentOrangeValue} />,
    },
    // FINAL
    { 
      'title': <b>TU SOLICITUD ESTARÁ VALIDADA POR UN <span style={{ color: '#cc9a36' }}>CATADOR Q GRADER</span> CERTIFICADO POR <span style={{ color: '#cc9a36' }}>EL COFFEE QUALITY INSTITUTE</span></b>,
      'subtitle': 'La magia de la personalización de Rúbrica es posible gracias a la selección de café y a la mezcla entre proceso y tueste adecuados.',
      'content': <Result rubrica={rubrica} />,
    },
    { 
      'title': <b>ESTA ES TU <span style={{ color: '#cc9a36' }}>RÚBRICA</span></b>,
      'subtitle': '',
      'content': <Summary data={data} />,
    },
  ]

  return (
    <Box style={{ position: 'relative' }}>
      {
        backgrounds[step] !== undefined ?
        <Box
          component='img'
          m={2}
          src={backgrounds[step].left.path}
          sx= {{ display: { xs: 'none', md: 'block' }, width: backgrounds[step].left.width }}
          style={{ 
            left: backgrounds[step].left.left, 
            top: backgrounds[step].left.top, 
            position: 'absolute',
          }}
        /> : <div></div>
      }
      {
        backgrounds[step] !== undefined ?
        <Box
          component='img'
          m={2}
          src={backgrounds[step].right.path}
          sx= {{ display: { xs: 'none', md: 'block' }, width: backgrounds[step].right.width }}
          style={{ 
            right: backgrounds[step].right.right, 
            top: backgrounds[step].right.top, 
            position: 'absolute',
          }}
        /> : <div></div>
      }
      <Container maxWidth={ step === 19 ? 'lg' : 'sm' }>
        <Card style={{ border: 'none' }}>
          <CardContent align='center' sx={{ display: step === 19 ? 'none' : 'inherit', pt: 4 }}>
            <LinearProgress color='inherit' variant='determinate' value={progress} />
          </CardContent>
          <CardContent align='center' sx={{ display: step === 19 ? 'none' : 'inherit', height: 128 }}>
            <Logo blue={blue} orange={orange} red={red} yellow={yellow} />
          </CardContent>
          <CardContent align='center' sx={{ p: 2 }}>
            <Typography fontFamily='Righteous' variant='h5' gutterBottom>
              {items[step].title}
            </Typography>
            <Typography variant='body1'>
              {items[step].subtitle}
            </Typography>
          </CardContent>
          <CardContent sx={{ p: 2 }}>
            { items[step].content }
          </CardContent>
          <CardActions sx={{ alignItems: 'flex-end', justifyContent: 'center', py: 4 }}>
            <Button color='primary' disableElevation size='large' variant='contained' style={{ color: 'white', display: step > 0 ? 'unset' : 'none' }} sx={{ borderRadius: 8 }} onClick={() => setStep(step - 1)}>
              REGRESAR
            </Button>
            <Button color='rubricaBlue' disableElevation size='large' variant='contained' style={{ color: 'white', display: step === 0 ? 'unset' : 'none' }} sx={{ borderRadius: 8 }} onClick={ () => setStep(1) }>
              EMPEZAR
            </Button>
            {/* disabled={ step !== 3 ? values[step] === undefined : Object.values(values[step]).filter(Boolean).length !== 4 } */}
            <Button disabled={disabled} color='rubricaRed' disableElevation size='large' variant='contained' style={{ color: 'white', display: step > 0 && step < (items.length - 1) ? 'unset' : 'none' }} sx={{ borderRadius: 8 }} onClick={() => setStep(step + 1)}>
              SIGUIENTE
            </Button>
          </CardActions>
        </Card>
      </Container>
    </Box>
    
  )
}

export default Wizard