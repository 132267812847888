const backgrounds = [
  {
    'left': {
      'left': '-5%',
      'path': '/assets/images/wizard/wizard_0_left.png',
      'top': '20%',
      'width': '22%'
    },
    'right': {
      'path': '/assets/images/wizard/wizard_0_right.png',
      'right': '-3%',
      'top': '5%',
      'width': '15%'
    }
  },
  {
    'left': {
      'left': '-5%',
      'path': '/assets/images/wizard/wizard_1_left.png',
      'top': '70%',
      'width': '22%'
    },
    'right': {
      'path': '/assets/images/wizard/wizard_1_right.png',
      'right': '-10%',
      'top': '5%',
      'width': '30%'
    }
  },
  {
    'left': {
      'left': '-8%',
      'path': '/assets/images/wizard/wizard_2_left.png',
      'top': '15%',
      'width': '30%'
    },
    'right': {
      'path': '/assets/images/wizard/wizard_2_right.png',
      'right': '-4%',
      'top': '70%',
      'width': '12%'
    }
  },
  {
    'left': {
      'left': '-5%',
      'path': '/assets/images/wizard/wizard_3_left.png',
      'top': '45%',
      'width': '15%'
    },
    'right': {
      'path': '/assets/images/wizard/wizard_3_right.png',
      'right': '-10%',
      'top': '35%',
      'width': '25%'
    }
  },
  {
    'left': {
      'left': '-8%',
      'path': '/assets/images/wizard/wizard_4_left.png',
      'top': '0%',
      'width': '20%'
    },
    'right': {
      'path': '/assets/images/wizard/wizard_4_right.png',
      'right': '-5%',
      'top': '40%',
      'width': '15%'
    }
  },
  {
    'left': {
      'left': '-4%',
      'path': '/assets/images/wizard/wizard_5_left.png',
      'top': '60%',
      'width': '15%'
    },
    'right': {
      'path': '/assets/images/wizard/wizard_5_right.png',
      'right': '-5%',
      'top': '0%',
      'width': '25%'
    }
  },
  {
    'left': {
      'left': '-4%',
      'path': '/assets/images/wizard/wizard_6_left.png',
      'top': '15%',
      'width': '25%'
    },
    'right': {
      'path': '/assets/images/wizard/wizard_6_right.png',
      'right': '-5%',
      'top': '15%',
      'width': '15%'
    }
  },
  {
    'left': {
      'left': '-6%',
      'path': '/assets/images/wizard/wizard_7_left.png',
      'top': '40%',
      'width': '15%'
    },
    'right': {
      'path': '/assets/images/wizard/wizard_7_right.png',
      'right': '-10%',
      'top': '15%',
      'width': '25%'
    }
  },
  {
    'left': {
      'left': '-10%',
      'path': '/assets/images/wizard/wizard_8_left.png',
      'top': '10%',
      'width': '25%'
    },
    'right': {
      'path': '/assets/images/wizard/wizard_8_right.png',
      'right': '-5%',
      'top': '20%',
      'width': '15%'
    }
  },
  {
    'left': {
      'left': '-4%',
      'path': '/assets/images/wizard/wizard_9_left.png',
      'top': '35%',
      'width': '15%'
    },
    'right': {
      'path': '/assets/images/wizard/wizard_9_right.png',
      'right': '-12%',
      'top': '25%',
      'width': '30%'
    }
  },
  {
    'left': {
      'left': '-5%',
      'path': '/assets/images/wizard/wizard_10_left.png',
      'top': '10%',
      'width': '35%'
    },
    'right': {
      'path': '/assets/images/wizard/wizard_10_right.png',
      'right': '-5%',
      'top': '15%',
      'width': '15%'
    }
  },
  {
    'left': {
      'left': '-5%',
      'path': '/assets/images/wizard/wizard_11_left.png',
      'top': '40%',
      'width': '15%'
    },
    'right': {
      'path': '/assets/images/wizard/wizard_11_right.png',
      'right': '-5%',
      'top': '15%',
      'width': '25%'
    }
  },
  {
    'left': {
      'left': '-10%',
      'path': '/assets/images/wizard/wizard_12_left.png',
      'top': '10%',
      'width': '30%'
    },
    'right': {
      'path': '/assets/images/wizard/wizard_12_right.png',
      'right': '-5%',
      'top': '30%',
      'width': '15%'
    }
  },
  {
    'left': {
      'left': '-6%',
      'path': '/assets/images/wizard/wizard_13_left.png',
      'top': '50%',
      'width': '15%'
    },
    'right': {
      'path': '/assets/images/wizard/wizard_13_right.png',
      'right': '-10%',
      'top': '25%',
      'width': '25%'
    }
  },
  {
    'left': {
      'left': '-5%',
      'path': '/assets/images/wizard/wizard_14_left.png',
      'top': '-10%',
      'width': '15%'
    },
    'right': {
      'path': '/assets/images/wizard/wizard_14_right.png',
      'right': '-5%',
      'top': '-10%',
      'width': '25%'
    }
  },
  {
    'left': {
      'left': '-10%',
      'path': '/assets/images/wizard/wizard_15_left.png',
      'top': '10%',
      'width': '30%'
    },
    'right': {
      'path': '/assets/images/wizard/wizard_15_right.png',
      'right': '-5%',
      'top': '15%',
      'width': '15%'
    }
  },
  {
    'left': {
      'left': '-5%',
      'path': '/assets/images/wizard/wizard_16_left.png',
      'top': '40%',
      'width': '15%'
    },
    'right': {
      'path': '/assets/images/wizard/wizard_16_right.png',
      'right': '-5%',
      'top': '0%',
      'width': '25%'
    }
  },
  {
    'left': {
      'left': '-8%',
      'path': '/assets/images/wizard/wizard_17_left.png',
      'top': '18%',
      'width': '30%'
    },
    'right': {
      'path': '/assets/images/wizard/wizard_17_right.png',
      'right': '-5%',
      'top': '-10%',
      'width': '15%'
    }
  }
]

export default backgrounds