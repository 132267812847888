import React, { useState } from 'react'

import {
  FormControl,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
} from '@mui/material'

const Cup = (props) => {
  const { current, getCurrentValue } = props

  const values = {
    'cup_1': 3,
    'cup_2': 2.5,
    'cup_3': 2,
    'cup_4': 1.5,
    'cup_5': 1,
  }
  
  const [value, setValue] = useState(current !== undefined ? current.id : '')

  const handleRadioChange = (event) => {
    setValue(event.target.value)
    getCurrentValue({
      'id': event.target.value,
      'value': values[event.target.value],
    })
  }

  return (
    <Grid container alignContent='center' alignItems='center' direction='column' justifyContent='center'>
      <Grid item xs={12}>
        <FormControl>
          <RadioGroup value={value} onChange={handleRadioChange}>
            <FormControlLabel value='cup_1' control={<Radio />} label='Ristretto menos de 20 ml (7g)' />
            <FormControlLabel value='cup_2' control={<Radio />} label='Espresso entre 30 y 40 ml (12g)' />
            <FormControlLabel value='cup_3' control={<Radio />} label='Lungo / Cappuccino entre 100 y 120 ml (14g)' />
            <FormControlLabel value='cup_4' control={<Radio />} label='Americano entre 200 y 240 ml (16g)' />
            <FormControlLabel value='cup_5' control={<Radio />} label='Extra largo más de 300 ml (25g)' />
          </RadioGroup>
        </FormControl>
      </Grid>
    </Grid>
  )
}

export default Cup