import React from 'react'

import {
  Grid
} from '@mui/material'

import Wizard from 'pages/wizard'

const Create = (props) => {
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Wizard />
      </Grid>
    </Grid>
  )
}

export default Create