import React, { useState } from 'react'

import {
  FormControl,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
} from '@mui/material'

const Moment = (props) => {
  const { current, getCurrentValue } = props

  const values = {
    'moment_1': 3,
    'moment_2': 3,
    'moment_3': 3,
    'moment_4': 3,
    'moment_5': 3,
    'moment_6': 3,
  }
  
  const [value, setValue] = useState(current !== undefined ? current.id : '')

  const handleRadioChange = (event) => {
    setValue(event.target.value)
    getCurrentValue({
      'id': event.target.value,
      'value': values[event.target.value],
    })
  }

  return (
    <Grid container alignContent='center' alignItems='center' direction='column' justifyContent='center'>
      <Grid item xs={12}>
        <FormControl>
          <RadioGroup value={value} onChange={handleRadioChange}>
            <FormControlLabel value='moment_1' control={<Radio />} label='Antes de desayunar' />
            <FormControlLabel value='moment_2' control={<Radio />} label='Durante el desayuno' />
            <FormControlLabel value='moment_3' control={<Radio />} label='Durante la mañana, después de desayunar' />
            <FormControlLabel value='moment_4' control={<Radio />} label='Durante la comida / sobremesa' />
            <FormControlLabel value='moment_5' control={<Radio />} label='En la tarde, después de comer' />
            <FormControlLabel value='moment_6' control={<Radio />} label='Por la noche para acompañar la cena' />
          </RadioGroup>
        </FormControl>
      </Grid>
    </Grid>
  )
}

export default Moment