import { useState } from 'react'

export const useOpenPay = () => {
  const [sessionId, setSessionId] = useState('')
  const [tokenId, setTokenId] = useState('')

  const init = () => {
    window.OpenPay.setId(process.env.REACT_APP_OPENPAY_ID)
    window.OpenPay.setApiKey(process.env.REACT_APP_OPENPAY_PK)
    window.OpenPay.setSandboxMode(false)// https://github.com/open-pay/openpay-js#fraud-detection-using-device-data
  }

  const generateSessionId = () => {
    setSessionId(window.OpenPay.deviceData.setup())
  }

  const validateCardNumber = (number) => {
    const valid = window.OpenPay.card.validateCardNumber(number)
    const type = window.OpenPay.card.cardType(number)
    return {
      'type': type,
      'valid': valid,
    }
  }

  const validateCardCvv = (cvv) => {
    const valid = window.OpenPay.card.validateCVC(cvv)
    return {
      'valid': valid,
    }
  }

  const onTokenSuccess = (response) => {
    var tokenId = response.data.id
    setTokenId(tokenId)
  }

  const onTokenError = (response) => {
    var desc = response.data.description !== undefined ?
        response.data.description : response.message
    console.log(desc)
  }

  const createToken = (token) => {
    window.OpenPay.token.create(token, onTokenSuccess, onTokenError)
  }

  return {
    createToken,
    generateSessionId,
    init,
    sessionId,
    tokenId,
    validateCardNumber,
    validateCardCvv,
  }
}