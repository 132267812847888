import React, { useEffect, useState } from 'react'

import { useNavigate } from 'react-router-dom'

import useAuth from 'hooks/auth'

import {
  Box,
  Button,
  Grid,
  Typography,
} from '@mui/material'

import Label from 'pages/label'

const Home = (props) => {

  const { auth, firestore } = useAuth()

  const navigate = useNavigate()

  const { user } = auth
  const { getMyRubrica, myRubrica } = firestore

  const [data, setData] = useState()

  useEffect(() => {
    getMyRubrica(user.uid)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    setData(myRubrica)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [myRubrica])

  const onProceed = () => {
    localStorage.setItem('data', JSON.stringify(data))
    navigate('checkout')
  }

  const HasRubrica = (
    <Grid container spacing={2}>
      <Grid item xs={6}>
        <Label data={data} />
      </Grid>
      <Grid item xs={6}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Typography variant='h5' gutterBottom>
              <b>Acerca de...</b>
            </Typography>
            <Typography variant='body1'>
              Menos del 10% de todo el café que se produce es considerado un café de especialidad, donde el compromiso con la calidad se vigila meticulosamente en cada etapa.
            </Typography>
            <br/>
            <Typography variant='body1'>
              Un productor con ese nivel de atención al detalle merece un pago justo y superior. Tu compra garantiza ese pago y llega, sin intermediarios, a manos de nuestros productores avalados.
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Button fullWidth disableElevation variant='contained' size='large' onClick={onProceed} sx={{ borderRadius: 8 }}>
              Continuar con la compra
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )

  return (
    <Box>
      {
        data !== undefined ? (
          HasRubrica
        ) : (
        <Grid container spacing={2} sx={{ p: 2 }}>
          <Grid item xs={12}>
            <Typography variant='h5' gutterBottom>
              <b>¡Bienvenido!</b>
            </Typography>
            <Typography variant='body1'>
              Al parecer no has creado ninguna rúbrica.
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Button disableElevation variant='contained' size='large' onClick={() => navigate('crear')}>
              Crear mi rúbrica
            </Button>
          </Grid>
        </Grid>
        )
      }
    </Box>
  )
}

export default Home